import lStorage from "@/services/localStorage";
import router from "@/router";

const logout = function () {
  lStorage.remove("user");
  lStorage.remove("jwt");
  router.push({ name: "login" });
};

const userService = {
  logout
};

export default userService;
