<template>
  <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click="showSidebar">
        <span class="navbar-toggler-icon"></span>
      </navbar-toggle-button>
      <router-link class="navbar-brand" to="/">
        <img :src="logo" class="navbar-brand-img" alt="..." />
      </router-link>

      <slot name="mobile-right">
        <ul class="nav align-items-center d-md-none">
          <!-- <base-dropdown class="nav-item" position="right">
            <template v-slot:title>
              <a
                class="nav-link nav-link-icon"
                href="#"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="ni ni-bell-55"></i>
              </a>
            </template>

            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">Something else here</a>
          </base-dropdown> -->
          <base-dropdown class="nav-item" position="right">
            <template v-slot:title>
              <a class="nav-link" href="#" role="button">
                <div class="media align-items-center">
                  <span class="avatar avatar-sm rounded-circle">
                    <img alt="Image placeholder" :src="userIconUrl" />
                  </span>
                </div>
              </a>
            </template>

            <!-- <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">Welcome!</h6>
            </div> -->
            <router-link to="/profile" class="dropdown-item">
              <i class="ni ni-single-02"></i>
              <span>My profile</span>
            </router-link>
            <router-link to="/business_accounts" class="dropdown-item">
              <i class="ni ni-briefcase-24"></i>
              <span>Business Accounts</span>
            </router-link>
            <div class="dropdown-divider"></div>
            <div class="dropdown-item" @click="userLogout()">
              <i class="ni ni-user-run"></i>
              <span>Log out</span>
            </div>
          </base-dropdown>
        </ul>
      </slot>
      <slot></slot>
      <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button @click="closeSidebar"></navbar-toggle-button>
            </div>
          </div>
        </div>

        <ul class="navbar-nav">
          <slot name="links"> </slot>
        </ul>
        <!--        &lt;!&ndash;Divider&ndash;&gt;-->
        <!--        <hr class="my-3" />-->
        <!--        &lt;!&ndash;Heading&ndash;&gt;-->
        <!--        <h6 class="navbar-heading text-muted">Documentation</h6>-->
        <!--        &lt;!&ndash;Navigation&ndash;&gt;-->
        <!--        <ul class="navbar-nav mb-md-3">-->
        <!--          <li class="nav-item">-->
        <!--            <a-->
        <!--              class="nav-link"-->
        <!--              href="https://demos.creative-tim.com/vue-argon-dashboard/documentation"-->
        <!--            >-->
        <!--              <i class="ni ni-spaceship"></i> Getting started-->
        <!--            </a>-->
        <!--          </li>-->
        <!--          <li class="nav-item">-->
        <!--            <a-->
        <!--              class="nav-link"-->
        <!--              href="https://demos.creative-tim.com/vue-argon-dashboard/documentation/foundation/colors.html"-->
        <!--            >-->
        <!--              <i class="ni ni-palette"></i> Foundation-->
        <!--            </a>-->
        <!--          </li>-->
        <!--          <li class="nav-item">-->
        <!--            <a-->
        <!--              class="nav-link"-->
        <!--              href="https://demos.creative-tim.com/vue-argon-dashboard/documentation/components/alerts.html"-->
        <!--            >-->
        <!--              <i class="ni ni-ui-04"></i> Components-->
        <!--            </a>-->
        <!--          </li>-->
        <!--        </ul>-->
      </div>
      <div class="dashboard-col-select mb-5 mb-xl-0">
        <SelectBA :user_id="user.id" :service_path="selected_service_path" @selectedBA="selectedBA" />
      </div>
    </div>
  </nav>
</template>
<script>
import { mapGetters } from "vuex";
import lStorage from "@/services/localStorage";
import store from "@/store";
import NavbarToggleButton from "@/components/NavbarToggleButton";
import SelectBA from "../../views/Dashboard/SelectBA.vue";
import userService from "@/services/user";

export default {
  name: "sidebar",
  components: {
    NavbarToggleButton,
    SelectBA,
  },
  props: {
    logo: {
      type: String,
      default: window.location.origin + "/img/brand/logo_64x64.svg",
      description: "Sidebar app logo",
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        "Whether sidebar should autoclose on mobile when clicking an item",
    },
  },
  data() {
    return {
      selected_service_path: '',
      userIconUrl: window.location.origin + "/img/theme/user.png"
    }
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
    userLogout() {
      userService.logout();
    },
    selectedBA(account) {
      let user = lStorage.getObject("user");
      let currentBusinessAccount = {
        id: account.id,
        name: account.name,
        path: account.path,
        created_at: account.created_at,
        updated_at: account.updated_at
      };
      user.currentBusinessAccount = currentBusinessAccount;
      lStorage.setObject("user", user);
      store.commit('user/setCurrentBusinessAccount', currentBusinessAccount);
      console.log('vuex: ', store.getters[ 'user/getUser' ])
    },
  },
  beforeUnmount() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
};
</script>
