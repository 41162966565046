<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">
                    {{ title }}
                  </h3>
                </div>
                <div class="col text-right">
                  <base-button
                    type="primary"
                    size="sm"
                    @click="modals.addIntegration = true"
                  >
                    Add integration
                  </base-button>
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <base-table
                class="table align-items-center table-flush table-integration"
                :thead-classes="'thead-light'"
                tbody-classes="list"
                :data="integrations"
              >
                <template v-slot:columns>
                  <th>Integration</th>
                  <th>Service</th>
                  <th>Account</th>
                  <th>Date Success Sync</th>
                  <th>Status Last Sync</th>
                  <th>Off/On</th>
                  <th></th>
                </template>

                <template v-slot:default="row">
                  <th scope="row">
                    <div class="media align-items-center">
                      <!--              <a href="#" class="avatar rounded-circle mr-3">-->
                      <!--                <img alt="Image placeholder" :src="row.item.img" />-->
                      <!--              </a>-->
                      <div class="media-body">
                        <span class="name mb-0 text-sm">{{
                          row.item.service.name
                        }}</span>
                      </div>
                    </div>
                  </th>
                  <th scope="row">
                    <div class="media align-items-center">
                      <!--              <a href="#" class="avatar rounded-circle mr-3">-->
                      <!--                <img alt="Image placeholder" :src="row.item.img" />-->
                      <!--              </a>-->
                      <div class="media-body">
                        <span class="name mb-0 text-sm">{{
                          row.item.service.service
                        }}</span>
                      </div>
                    </div>
                  </th>
                  <td class="account">
                    {{ row.item.name }}
                  </td>
                  <td class="start sync">
                    <span v-if="row.item.date_finish_sync !== undefined">{{ row.item.date_finish_sync }}</span>
                  </td>
                  <td class="status sync">
                    <span 
                      v-if="row.item.status_last_sync !== undefined"
                      :class="{
                        'text-red': row.item.status_last_sync == 'Error',
                        'text-green': row.item.status_last_sync == 'Finish',
                      }"
                    >
                      {{ row.item.status_last_sync }}
                    </span>
                  </td>
                  <td class="on-off">
                    <base-switch :value="returnIntegrationStatus(row.item.is_active)"
                         class="ml-1"
                         @input="changeIntegrationStatus($event, row.item)"
                    ></base-switch>
                  </td>
                  <td class="settings text-right table-integrations_button">
                    <base-button
                      class="button-settings"
                      type="primary"
                      size="sm"
                      @click="openPopupStartSync(row.item)"
                    >
                      <i
                        class="ni ni-cloud-download-95 icon_button"
                        title="Start sync"
                      ></i>
                    </base-button>
                    <router-link
                      :to="{
                        name: 'admin_ba_integrations_settings',
                        params: {
                          service_path: row.item.service.path,
                          id_integrations: row.item.id,
                        },
                      }"
                    >
                      <base-button
                        class="button-settings"
                        type="warning"
                        size="sm"
                      >
                        <!-- Settings -->
                        <i
                          class="ni ni-settings-gear-65 icon_button"
                          title="Settings"
                        ></i>
                      </base-button>
                    </router-link>
                    <base-button
                      class="button-delete"
                      type="default"
                      size="sm"
                      :disabled="isIntegrationDeleteLoading(row.item)"
                      @click="deleteIntegration(row.item)"
                    >
                      <!-- Delete -->
                      <img
                        v-bind:src="'/img/icons/icons8-trash.svg'"
                        class="icon_white cursor-pointer icon_size"
                        title="Delete"
                      />
                    </base-button>
                    <div
                      v-show="isIntegrationDeleteLoading(row.item)"
                      class="loading_row"
                    >
                      <img src="/img/Reload-1s-200px1.svg" alt="" />
                    </div>
                  </td>
                </template>
              </base-table>
            </div>

            <div class="card-footer d-flex justify-content-end">
              <!--      <base-pagination total="30"></base-pagination>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal
    v-model:show="modals.addIntegration"
    modalClasses="modal-add-integrations"
  >
    <template v-slot:header>
      <h3 class="modal-title" id="modal-title-default">Add integration</h3>
    </template>

    <div class="flex flex-wrap">
      <table>
        <tr style="height: 50px">
          <th style="width: 200px">Integration</th>
          <th style="width: 350px">Info</th>
          <th style="width: 200px"></th>
        </tr>
        <tr style="height: 50px" v-for="service in services" :key="service.id">
          <td>{{ service.name }}</td>
          <td style="height: 150px">{{ service.info }}</td>
          <td style="padding: 0px 0px 0px 80px">
            <base-button
              type="primary"
              size="sm"
              :block="true"
              @click="addIntegration(service)"
              >Add</base-button
            >
          </td>
        </tr>
      </table>
      <p v-if="Object.keys(services).length == 0">No services available</p>
    </div>

    <template v-slot:footer>
      <base-button
        type="link"
        class="ml-auto"
        @click="modals.addIntegration = false"
        >Close
      </base-button>
    </template>
  </modal>

  <modal v-model:show="modals.start_sync"
    modalClasses="modal-start-sync"
  >
    <template v-slot:header>
      <h3 class="modal-title" id="modal-title-default">Synchronization</h3>
    </template>

    <div class="mb-4">
      <p class="mb-2">Date start:</p>
      <datepicker v-model="sync_data.date_start" inputFormat="dd-MM-yyyy" />
    </div>
    <div class="mb-4">
      <p class="mb-2">Date end:</p>
      <datepicker v-model="sync_data.date_end" inputFormat="dd-MM-yyyy" />
    </div>

    <div class="input_checkbox ">
      <input type="checkbox" v-model="sync_data.full" />
      <p>Full</p>
    </div>

    <template v-slot:footer>
      <base-button
        type="primary"
        class="ml-auto"
        @click="startSync()"
        >Start sync
      </base-button>
    </template>
  </modal>

  <modal v-model:show="params_modal_message.show"
    modalClasses="modal_message"
  >
    <template v-slot:header>
      <!-- <h3 class="modal-title" id="modal-title-default"></h3> -->
    </template>

    <p
        class="modal_message_text"
        :class="[params_modal_message.status]"
    ><strong>{{params_modal_message.message}}</strong></p>


  </modal>
</template>
<script>
import { mapGetters } from "vuex";
import apiServiceAdmin from "@/services/admin";
import apiService from "@/services/api";
import Datepicker from "vue3-datepicker";

export default {
  name: "admin-integrations",
  components: {
    Datepicker,
  },
  props: {
    class: {
      type: String,
      default: "",
      description: "Class container",
    },
  },
  data() {
    return {
      business_account_id: null,
      title: "Connected integrations",
      integrations: [],
      services: [],
      modals: {
        addIntegration: false,
        start_sync: false,
      },
      sync_data: {
        integration: {},
        date_start: new Date(),
        date_end: new Date(),
        full: false
      },
      params_modal_message: {
        show: false,
        status: '',
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  created() {
    this.setBusinessAccountId();
    this.getServices();
    console.log("user", this.user);
  },
  methods: {
    setDateStart(){
      var date = new Date();
      date.setDate(date.getDate() - 7);

      console.log(date);
    },
    setBusinessAccountId() {
      this.business_account_id = this.$route.params.id_ba;
      console.log("this.business_account_id", this.business_account_id);
      console.log("this.$route", this.$route);
    },
    addIntegration(service) {
      console.log("addIntegration service", service);
      apiService.addIntegration(this.user.id, service).then((response) => {
        console.log(response);
        let url_redirect = response.data.service_response.data;
        console.log(url_redirect);
        window.location.replace(url_redirect);
      });
    },
    deleteIntegration(integration) {
      integration.delete_loading = true;
      apiService
        .deleteIntegration({
          user_id: this.user.id,
          service_path: integration.service.path,
          id_integration: integration.id,
        })
        .then((response) => {
          console.log(response);
          this.getServices();
        })
        .catch(() => {
          integration.delete_loading = false;
        });
    },
    isIntegrationDeleteLoading(integration) {
      return integration.delete_loading !== undefined &&
        integration.delete_loading == true
        ? true
        : false;
    },
    getServices() {
      let data = {
        user_id: this.user.id,
        ba_id: this.business_account_id,
      };
      console.log('data', data);
      apiServiceAdmin.getBAServices(data).then((response) => {
        console.log(response);
        this.services = response.data.data.services;
        this.integrations = response.data.data.integrations;
      });
    },
    openPopupStartSync(item) {
      this.modals.start_sync = true;
      var date = new Date();
      date.setDate(date.getDate() - 7);

      this.sync_data.date_start = date;
      this.sync_data.date_end = new Date();
      this.sync_data.integration = item;
    },
    startSync(){
      console.log('sync_data', this.sync_data);

      let data = {
        date_from: this.sync_data.date_start,
        date_to: this.sync_data.date_end,
        full: this.sync_data.full,
        ba_id: this.business_account_id,
        integration_id: this.sync_data.integration.integration_id,
      };
      let service_path = this.sync_data.integration.service.path;
      console.log('data', data);
      apiServiceAdmin.startSync(service_path, data).then((response) => {
        console.log('response', response);
        this.modals.start_sync = false;
        this.params_modal_message = {
          show: true,
          status: 'success',
          message: response.data.service_response.data.status,
        };
      })
      .catch(() => {
        this.modals.start_sync = false;
        this.params_modal_message = {
            show: true,
            status: 'error',
            message: 'Error start sync!',
        };
      });
    },
    returnIntegrationStatus(integration_status){
      return (integration_status == 1) ? true : false
    },
    changeIntegrationStatus($event, account){
      console.log('changeIntegrationStatus', account);
      apiService
        .updateStatusIntegration({
          'user_id': this.user.id,
          'service_path': account.service.path,
          'integration_id': account.id,
          'status': $event,
        })
        .then((response) => {
          console.log(response);
          if (response.data.service_response.success == true){
            account.is_active = Boolean($event);
          }
        });
    },
  },
};
</script>
<style></style>
