<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <!--        <div class="card-header bg-transparent pb-5">-->
        <!--          <div class="text-muted text-center mt-2 mb-3">-->
        <!--            <small>Sign up with</small>-->
        <!--          </div>-->
        <!--          <div class="btn-wrapper text-center">-->
        <!--            <a href="#" class="btn btn-neutral btn-icon">-->
        <!--              <span class="btn-inner&#45;&#45;icon"-->
        <!--                ><img src="img/icons/common/github.svg"-->
        <!--              /></span>-->
        <!--              <span class="btn-inner&#45;&#45;text">Github</span>-->
        <!--            </a>-->
        <!--            <a href="#" class="btn btn-neutral btn-icon">-->
        <!--              <span class="btn-inner&#45;&#45;icon"-->
        <!--                ><img src="img/icons/common/google.svg"-->
        <!--              /></span>-->
        <!--              <span class="btn-inner&#45;&#45;text">Google</span>-->
        <!--            </a>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Register</small>
          </div>
          <form role="form" @submit.prevent="handleSubmit">
            <base-input
              formClasses="input-group-alternative"
              placeholder="Name"
              addon-left-icon="ni ni-hat-3"
              v-model="model.name"
              :valid="validate.name.isValid"
              :error="validate.name.error"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="model.email"
              focused
              :valid="validate.email.isValid"
              :error="validate.email.error"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative"
              placeholder="Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.password"
              :valid="validate.password.isValid"
              :error="validate.password.error"
            >
            </base-input>

            <base-input
              formClasses="input-group-alternative"
              placeholder="Confirm Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="model.passwordConfirm"
              :valid="validate.passwordConfirm.isValid"
              :error="validate.passwordConfirm.error"
            >
            </base-input>

            <!--            <div class="text-muted font-italic">-->
            <!--              <small-->
            <!--                >password strength:-->
            <!--                <span class="text-success font-weight-700">strong</span></small-->
            <!--              >-->
            <!--            </div>-->

            <!--            <div class="row my-4">-->
            <!--              <div class="col-12">-->
            <!--                <base-checkbox class="custom-control-alternative">-->
            <!--                  <span class="text-muted"-->
            <!--                    >I agree with the <a href="#!">Privacy Policy</a></span-->
            <!--                  >-->
            <!--                </base-checkbox>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                @click.prevent="handleSubmit"
              >
                Create account
              </base-button>
            </div>
            <div class="text-danger font-italic mt-2 text-center">
              <small>{{ formSubmitError }}</small>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <!--          <a href="#" class="text-light">-->
          <!--            <small>Forgot password?</small>-->
          <!--          </a>-->
        </div>
        <div class="col-6 text-right">
          <router-link to="/login" class="text-light">
            <small>Sign in to your account</small>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import lStorage from "@/services/localStorage";
import store from "@/store";
import apiService from "@/services/api";
export default {
  name: "register",
  data() {
    return {
      model: {
        name: "",
        email: "",
        password: "",
        passwordConfirm: "",
      },
      validate: {
        name: {
          isValid: null,
          error: "",
          typeValid: [
            {
              type: "required",
              errorMess: "The field must not be empty",
            },
          ],
        },
        email: {
          isValid: null,
          error: "",
          typeValid: [
            {
              type: "required",
              errorMess: "The field must not be empty",
            },
            {
              type: "email",
              errorMess: "Email not valid",
            },
          ],
        },
        password: {
          isValid: null,
          error: "",
          typeValid: [
            {
              type: "required",
              errorMess: "The field must not be empty",
            },
          ],
        },
        passwordConfirm: {
          isValid: null,
          error: "",
          typeValid: [
            {
              type: "required",
              errorMess: "The field must not be empty",
            },
          ],
        },
      },
      formIsValid: true,
      formSubmitError: "",
    };
  },
  watch: {
    "model.name"() {
      this.resetErrors("name");
    },
    "model.email"() {
      this.resetErrors("email");
    },
    "model.password"() {
      this.resetErrors("password");
    },
    "model.passwordConfirm"() {
      this.resetErrors("passwordConfirm");
    },
  },
  computed: {
  },
  methods: {
    resetErrors(type) {
      this.validate[type].isValid = null;
      this.validate[type].error = "";
      this.formSubmitError = "";
    },
    handleSubmit() {
      this.validateForm();
      if (this.formIsValid) {
        apiService
          .register({
            name: this.model.name,
            email: this.model.email,
            password: this.model.password,
            c_password: this.model.passwordConfirm,
          })
          .then((response) => {
            let data = response.data.data;
            if (response.data.success) {
              if (data.token != null && data.token != undefined) {
                lStorage.setObject("user", data.user);
                lStorage.set("jwt", data.token);
                store.commit("user/addUser", data.user);
                store.commit("user/addToken", data.token);
                if (this.$route.params.nextUrl != null) {
                  this.$router.push(this.$route.params.nextUrl);
                } else {
                  this.$router.push({ name: "business_accounts" });
                }
              } else {
                this.formSubmitError = "Error register!";
              }
            }
          })
          .catch((error) => {
            console.error("error reg", error);
            if (error.response) {
              console.log(error.response);
              this.formSubmitError =
                error.response.data.message + " " + error.response.data.data;
            }
          });
      } else {
        return console.log("form not valid");
      }
    },
    validateForm() {
      this.formIsValid = true;
      let errorMess = "";
      for (let key in this.validate) {
        this.validate[key]["error"] = "";

        this.validate[key]["typeValid"].forEach((typeValid) => {
          if (typeValid.type === "required") {
            this.validate[key]["isValid"] = this.model[key] !== "";
            errorMess = typeValid.errorMess;
          }
          if (this.validate[key]["isValid"] && typeValid.type === "email") {
            this.validate[key]["isValid"] = this.validateEmail(this.model[key]);
            errorMess = typeValid.errorMess;
          }
        });

        if (!this.validate[key]["isValid"]) {
          this.formIsValid = false;
          this.validate[key]["error"] = errorMess;
        }
      }
      if (this.model.password !== this.model.passwordConfirm) {
        this.validate.passwordConfirm.error = "Password mismatch";
        this.formIsValid = false;
      }
    },
    validateEmail(email) {
      let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      return re.test(String(email).toLowerCase());
    },
  },
};
</script>
<style></style>
