<template>
  <div class="modal-overlay">
    <div class="confirmation-modal">
      <p>{{ message }}</p>
      <div class="modal-buttons">
        <button class="btn btn-sm btn-confirm" @click="confirm">Confirm</button>
        <button class="btn btn-sm btn-cancel" @click="cancel">Cancel</button>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    message: String
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
    }
  }
};
</script>


<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirmation-modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.modal-buttons {
  margin-top: 10px;
  text-align: right;
}

.modal-buttons button {
  margin-left: 10px;
}

.btn-confirm {
  color: #fff;
  background-color: green;
  border-color: green;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn-cancel {
  color: #fff;
  background-color: red;
  border-color: red;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.btn:hover {
  color: #fff;
}

</style>
