<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent' : ''">
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button type="primary" size="sm" @click="checkForBusinessAccount">
            {{ btn }}
          </base-button>
        </div>
      </div>
    </div>

    <div class="table-responsive">
      <base-table class="table align-items-center table-flush table-integration"
        :class="type === 'dark' ? 'table-dark' : ''" :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list" :data="filteredIntegrations">
        <template v-slot:columns>
          <th>Title</th>
          <th>From</th>
          <th>To</th>
          <th>Status</th>
          <th>Off/On</th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            <div class="media align-items-center">
              <!--              <a href="#" class="avatar rounded-circle mr-3">-->
              <!--                <img alt="Image placeholder" :src="row.item.img" />-->
              <!--              </a>-->
              <div class="media-body">
                <span class="name mb-0 text-sm">{{
    row.item.name
  }}</span>
              </div>
            </div>
          </th>
          <td class="account">
            {{ this.all_services.find(item => item.id === row.item.receiving_service_id)?.name ?? "" }}
          </td>
          <td class="account">
            {{ this.all_services.find(item => item.id === row.item.sending_service_id)?.name ?? "" }}
          </td>
          <td class="account">
            {{ row.item.is_active ? "Active" : "Inactive" }}
          </td>
          <td class="on-off">
            <base-switch :value="returnIntegrationStatus(row.item.is_active)" class="ml-1"
              @input="changeIntegrationStatus($event, row.item)"></base-switch>
          </td>
        </template>
      </base-table>
    </div>

    <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent' : ''">
      <!--      <base-pagination total="30"></base-pagination>-->
    </div>
  </div>
  <modal v-model:show="modals.addIntegration" modalClasses="modal-add-integrations">
    <template v-slot:header>
      <h3 class="modal-title" id="modal-title-default">Add integration</h3>
    </template>

    <div class="flex flex-wrap">
      <table>
        <tr style="height: 50px">
          <th style="width: 200px">Integration</th>
          <th style="width: 350px">Info</th>
          <th style="width: 200px"></th>
        </tr>
        <tr style="height: 50px" v-for="service in services" :key="service.id">
          <td>{{ service.name }}</td>
          <td style="height: 150px">{{ service.info }}</td>
          <td style="padding: 0px 0px 0px 80px">
            <base-button type="primary" size="sm" :block="true" @click="addIntegration(service)">Add</base-button>
          </td>
        </tr>
      </table>
      <p v-if="Object.keys(services).length == 0">No services available</p>
    </div>

    <template v-slot:footer>
      <base-button type="link" class="ml-auto" @click="modals.addIntegration = false">Close
      </base-button>
    </template>
  </modal>
  <modal v-model:show="modals.addAuthData" modalClasses="modal-add-integrations">
    <template v-slot:header>
      <h3 class="modal-title" id="modal-title-default">
        Add Authorization Data
      </h3>
    </template>

    <template v-slot:default>
      <form class="auth-form" v-if="current_service &&
    current_service.auth_template &&
    current_service.auth_template.field_config
    " @submit.prevent="addAuthData">
        <div class="form-group" v-for="(config, key) in current_service.auth_template.field_config" :key="key">
          <label :for="key" :class="{ 'checkbox-label': config.type === 'boolean' }">{{ config.title }}</label>
          <input v-if="config.type !== 'boolean'" :id="key" :type="getInputType(config.type)" v-model="formData[ key ]"
            :readonly="key === 'client_id'" required />
          <div v-else class="checkbox-group">
            <input type="checkbox" :id="key" v-model="formData[ key ]" />
          </div>
        </div>

        <div class="form-group">
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>

        <div v-if="errorMessage" class="error-message">
          {{ errorMessage }}
        </div>
      </form>
    </template>

    <template v-slot:footer>
      <base-button type="link" class="ml-auto" @click="modals.addAuthData = false">Close</base-button>
    </template>
  </modal>
</template>
<script>
import { mapGetters } from "vuex";
import apiService from "@/services/api";

export default {
  name: "projects-table",
  props: {
    type: {
      type: String,
    },
    title: String,
    btn: String,
  },
  data() {
    return {
      integrations: [],
      services: [],
      all_services: [],
      modals: {
        addIntegration: false,
        addAuthData: false,
      },
      current_service: null,
    };
  },
  computed: {
    ...mapGetters("user", [
      "getUser",
      "getVuexBusinessAccounts",
      "getCurrentBusinessAccount",
    ]),
    filteredIntegrations() {
      return this.integrations.filter(
        (integrationItem) =>
          integrationItem.service_type === 'integration'
      );
    },
  },
  created() {
    this.getBusinessAccounts(this.getUser.id);
    this.getServices("receiving");
    this.getIntegrationsExports(this.getUser.id);
    this.getAllServices();
  },
  methods: {
    checkForBusinessAccount() {
      if (this.getVuexBusinessAccounts.length > 0) {
        this.modals.addIntegration = true;
      } else {
        this.$router.push({ name: "business_accounts" });
      }
    },
    getBusinessAccounts(userId) {
      return apiService.getBusinessAccountsByUserId(userId).then((response) => {
        this.$store.commit("user/setBusinessAccounts", response.data.data); // Committing to the store
        return response;
      });
    },
    getServicePath(serviceId) {
      const service = this.services.find((service) => service.id === serviceId);
      return service ? service.service_path : "Path not found";
    },
    getIntegrationsExports(user_id) {
      apiService.getIntegrationsExports(user_id).then((response) => {
        this.integrations = response.data;
        console.log("IntegrationsExports: ", this.integrations);
      });
    },
    addIntegration(service) {
      if (service.auth_template && typeof service.auth_template === "string") {
        service.auth_template = JSON.parse(service.auth_template);
      }
      this.current_service = service;
      if (service.auth_template == null) {
        apiService.addExport(this.getUser.id, service).then((response) => {
          let url_redirect = response.data.service_response.data;
          window.location.replace(url_redirect);
        });
      } else {
        this.modals.addIntegration = false;
        this.modals.addAuthData = true;
      }
    },
    addAuthData() {
      this.formData.created_by = this.getUser.id;
      this.formData.ba_id = this.getCurrentBusinessAccount.id;
      this.formData.sending_service_id = this.current_service.id;
      this.formData.sending_service_status = this.current_service.is_active;
      this.formData.service_type = this.current_service.type;
      this.formData.is_active = false;

      if (!this.formData.domain) {
        this.formData.domain = this.current_service.path;
      }

      apiService
        .addAuthData(this.formData)
        .then((response) => {
          if (!response.data.success) {
            // Handle the case where success is false. Keep the modal open, only show the error message
            this.errorMessage = response.data.message;
          } else {
            this.modals.addAuthData = false;
            this.$router.push(
              `/integrations/${response.data.integrations_export_id}/step2`
            );
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    deleteIntegration(integration) {
      integration.delete_loading = true;
      apiService
        .deleteIntegration({
          user_id: this.getUser.id,
          service_path: integration.service.path,
          id_integration: integration.id,
        })
        .then((response) => {
          console.log(response);
          this.getServices("receiving");
        })
        .catch(() => {
          integration.delete_loading = false;
        });
    },
    isIntegrationDeleteLoading(integration) {
      return integration.delete_loading !== undefined &&
        integration.delete_loading == true
        ? true
        : false;
    },
    getServices(type) {
      apiService.getServices(this.getUser.id, type).then((response) => {
        this.services = response.data.data.services;
      });
    },
    getAllServices() {
      apiService.getAllServices().then((response) => {
        this.all_services = response.data.data.services;
        console.log('ALL ', this.all_services)
      });
    },
    returnIntegrationStatus(integration_status) {
      return integration_status == 1 ? true : false;
    },
    changeIntegrationStatus($event, item) {
      apiService
        .updateStatusExport({
          'created_by': this.getUser.id,
          'export_id': item.id,
          'status': $event,
        })
        .then((response) => {
          if (response.data.success == true) {
            item.is_active = Boolean($event);
          }
        });
    },
    getInputType(typeString) {
      // You can expand this method to handle different types as needed
      return typeString.includes("string") ? "text" : "text"; // default to text for now
    },
  },
  watch: {
    "current_service.auth_template": {
      deep: true,
      handler() {
        if (
          this.current_service &&
          this.current_service.auth_template &&
          this.current_service.auth_template.field_config
        ) {
          this.formData = Object.keys(
            this.current_service.auth_template.field_config
          ).reduce((acc, key) => {
            // Predefine the client_id field with this.getUser.id if it exists
            if (key === "client_id" && this.user && this.getUser.id) {
              acc[ key ] = this.getUser.id;
            } else {
              acc[ key ] = "";
            }
            return acc;
          }, {});
        }
      },
    },
  },
};
</script>
