<template>
  <div class="position-relative dashboard_update_wrap">
    <base-button
        type="primary"
        block
        :disabled="loading_update_insights"
        @click="updateInsights()"
    >Update
    </base-button>
    <div v-show="loading_update_insights" class="loading_row">
      <img src="/img/Reload-1s-200px1.svg" alt="">
    </div>
  </div>
</template>

<script>
  import apiService from "../../services/api";
  import {mapGetters} from "vuex";

  export default {
    name: "ButtonUpdateInsights",
    props: {
      service_path: {
        type: String,
        require: true,
      },
      integration_id: {
        type: String,
        require: true,
      },
    },
    emits: ['update_insights'],
    data() {
      return {
        loading_update_insights: false,
      };
    },
    computed: {
      ...mapGetters({
        user: "user/getUser",
      }),
    },
    watch: {
    },
    methods: {
      updateInsights() {
        let user_id = this.user.id;

        this.loading_update_insights = true;

        return apiService
          .updateInsights(user_id, this.service_path, this.integration_id)
          .then((response) => {
            console.log('ButtonUpdateInsights updateInsights',response);
            this.loading_update_insights = false;
            this.$emit("update_insights", response.data.service_response.data.result);
            return response;
          })
          .catch((error) => {
            this.loading_update_insights = false;
            this.$emit("update_insights", false);
            return error;
          });
      },
    },
  }
</script>