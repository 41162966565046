<template>
  <div class="nav-wrapper ba_menu_wrapper">
    <ul
        class="nav nav-pills nav-fill"
    >
      <li class="nav-item">
        <router-link
            :to="{
              name: 'admin_ba_users',
              params: {
                id_ba: business_account_id,
              },
            }"
            @click="linkClick"
            class="nav-link"
        >
          <i class="ni ni-single-02 mr-2"></i>
          <span class="nav-link-text">Users</span>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link
            :to="{
              name: 'admin_ba_settings',
              params: {
                id_ba: business_account_id,
              },
            }"
            @click="linkClick"
            class="nav-link"
        >
          <i class="ni ni-settings-gear-65 mr-2"></i>
          <span class="nav-link-text">Settings</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>
<script>
  export default {
    name: "business-account-menu",
    props: {},
    computed: {},
    data() {
      return {
        business_account_id: null,
      };
    },
    methods: {
      setBusinessAccountId() {
        this.business_account_id = this.$route.params.id_ba;
        console.log('this.business_account_id',this.business_account_id);
        console.log('this.$route',this.$route);
      },
      linkClick() {
      },
    },
    created() {
      this.setBusinessAccountId();
    },
    watch: {},
  };
</script>
