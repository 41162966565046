<template>
    <div>
      <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
  
      </base-header>
  
      <div class="container-fluid mt--7">
        <div class="row">
          <div class="col">
            <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">                
                <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent' : ''">
                    <div class="row align-items-center">
                        <div class="col">
                        <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                            Сonfigure the integration
                        </h3>
                        </div>
                        <div class="col text-right">
                        <base-button type="warning" size="sm" @click="save()">
                            Save
                        </base-button>
                        <base-button type="success" size="sm" @click="saveAndActivate()">
                            Save and activate
                        </base-button>
                        </div>
                    </div>
                </div>

                <div class="card_body">
                    <base-alert type="warning" v-if="error_message != ''">
                        <strong>Error!</strong> {{error_message}}
                    </base-alert>
                    <div class="settings_integration_fb_container mt-4">
                        <div class="settings_integration_title_wrap">
                            <h3>Facebook:</h3>
                        </div>                        
                        <div class="settings_integration_wrap">
                            <div class="settings_integration_left">
                                <label class="mb-0">Page:</label>
                            </div>
                            <div class="settings_integration_right"
                                :class="{'validate_error': (form_data_validate.page_id != undefined && form_data_validate.page_id == false)}"
                            >
                                <Multiselect
                                    v-model="form_data.page_id"
                                    :options="fb_pages"
                                    placeholder="Select page"
                                    label="name"
                                    valueProp="page_id"
                                    :canDeselect="false"
                                    :searchable="true"
                                    @select="getForms()"
                                    @deselect="resetFormData()"
                                />
                            </div>
                            
                        </div>
                        <div class="settings_integration_wrap mt-4" >
                            <div class="settings_integration_left">
                                <label class="mb-0">Forms:</label>
                            </div>
                            <div class="settings_integration_right"
                                :class="{'validate_error': (form_data_validate.form_ids != undefined && form_data_validate.form_ids == false)}"
                            >

                                <Multiselect
                                    v-model="form_data.form_ids" 
                                    :options="fb_forms"                                   
                                    placeholder="Select forms"
                                    label="name"
                                    valueProp="lead_form_id"
                                    :canDeselect="false"
                                    :searchable="true"
                                    mode="tags"
                                    :disabled="form_data.page_id == null"
                                    >

                                    <template v-slot:option="{ option }">
                                        <span :class="{'option-is-used': option.is_used}">{{ option.name }}</span>
                                        <span class="is-used-info" v-if="option.is_used"> Is used!</span>
                                    </template>
                                </Multiselect>
                            </div> 
                        </div>
                    </div>
                    <div class="settings_keepin_container mt-5" v-if="form_data.form_ids.length > 0">
                        <div class="settings_integration_title_wrap">
                            <h3>Keepin deal:</h3>
                        </div> 
                        <div class="settings_integration_wrap">
                            <!-- <base-checkbox class="mb-3" v-model="form_data.create_lead">
                                Create lead
                            </base-checkbox> -->
                            <div class="settings_integration_left">
                                <label class="mb-0">Create lead:</label>
                            </div>
                            <div class="settings_integration_right settings_integration_checkbox_wrap">
                                <input type="checkbox" id="checkbox" v-model="form_data.create_lead" />
                            </div>
                            
                        </div>
                        <div class="settings_integration_wrap mt-4">
                            <div class="settings_integration_left">
                                <label class="mb-0">Pipeline:</label>
                            </div>
                            <div class="settings_integration_right">
                                <Multiselect
                                    v-model="form_data.pipeline_id"
                                    :options="keepin_info.deal.funnels"
                                    placeholder="Select pipeline"
                                    label="title"
                                    valueProp="id"
                                    :canDeselect="false"
                                    :searchable="true"
                                />
                            </div>
                            
                        </div>
                        <div class="settings_integration_wrap mt-4">
                            <div class="settings_integration_left">
                                <label class="mb-0">Name:</label>
                            </div>
                            <div class="settings_integration_right">
                                <input class="form-control" type="text" v-model="form_data.deal_name" required />
                            </div>
                            
                        </div>
                        <div class="settings_integration_wrap mt-4">
                            <div class="settings_integration_left">
                                <label class="mb-0">Amount:</label>
                            </div>
                            <div class="settings_integration_right">
                                <input class="form-control" type="number" v-model="form_data.deal_amount" required />
                            </div>
                            
                        </div>
                        <div class="settings_integration_wrap mt-4">
                            <div class="settings_integration_left">
                                <label class="mb-0">Currency:</label>
                            </div>
                            <div class="settings_integration_right">
                                <input class="form-control" type="text" v-model="form_data.deal_currency" required />
                            </div>
                            
                        </div>
                        <div class="settings_integration_wrap mt-4">
                            <div class="settings_integration_left">
                                <label class="mb-0">Stage:</label>
                            </div>
                            <div class="settings_integration_right">
                                <Multiselect
                                    v-model="form_data.stage_id"
                                    :options="keepin_info.deal.stages"
                                    placeholder="Select stage"
                                    label="name"
                                    valueProp="id"
                                    :canDeselect="false"
                                    :searchable="true"
                                />
                            </div>
                            
                        </div>
                        <div class="settings_integration_wrap mt-4">
                            <div class="settings_integration_left">
                                <label class="mb-0">Source:</label>
                            </div>
                            <div class="settings_integration_right">
                                <Multiselect
                                    v-model="form_data.source_id"
                                    :options="keepin_info.sources"
                                    placeholder="Select source"
                                    label="name"
                                    valueProp="id"
                                    :canDeselect="false"
                                    :searchable="true"
                                />
                            </div>
                            
                        </div>
                        <div class="settings_integration_wrap mt-4">
                            <div class="settings_integration_left">
                                <label class="mb-0">Responsible:</label>
                            </div>
                            <div class="settings_integration_right">
                                <Multiselect
                                    v-model="form_data.responsible_id"
                                    :options="keepin_info.users"
                                    placeholder="Select responsible"
                                    label="name"
                                    valueProp="id"
                                    :canDeselect="false"
                                    :searchable="true"
                                />
                            </div>
                            
                        </div>
                    </div>

                    <div class="settings_maping_container mt-5" v-if="form_data.form_ids.length > 0">
                        <div class="settings_integration_title_wrap">
                            <h3>Mapping fields:</h3>
                        </div> 
                        <div class="settings_integration_maping_wrap mt-4" 
                            v-for="(field_map, key) in form_data.fields_map" :key="key"
                        >
                                <div class="settings_integration_maping_left" 
                                    :class="{
                                        'maping_custom': (field_map.form_id == 'custom'),
                                        'validate_error': (form_data_validate.fields_map[key] != undefined && form_data_validate.fields_map[key].crm_id == false)
                                    }"
                                >
                                    <Multiselect
                                        v-model="field_map.crm_id"
                                        placeholder="Select keepin field"
                                        label="label"
                                        valueProp="id"
                                        :groups="true"
                                        :searchable="true"
                                        :options="keepin_fields"
                                        @select="selectKeepinMapingField(field_map, key)"
                                        @deselect="deselectKeepinMapingField(field_map, key)"
                                        @clear="deselectKeepinMapingField(field_map, key)"
                                    />
                                </div>
                                <div class="settings_integration_maping_right" 
                                    :class="{
                                        'maping_custom': (field_map.form_id == 'custom'),
                                        'validate_error': (form_data_validate.fields_map[key] != undefined && form_data_validate.fields_map[key].form_id == false)
                                    }"
                                >
                                    <Multiselect
                                        v-model="field_map.form_id"
                                        :options="(field_map.type_select == 'facebook') ? fb_fields : field_map.options"
                                        placeholder="Select field"
                                        label="label"
                                        valueProp="text"
                                        :canDeselect="false"
                                        :searchable="true"
                                        :disabled="field_map.crm_id == null"
                                        @select="selectFacebookMapingField(field_map, key)"
                                        @deselect="deselectFacebookMapingField(field_map, key)"
                                        @clear="deselectFacebookMapingField(field_map, key)"
                                    />
                                </div> 

                                <div class="settings_integration_maping_custom" 
                                    v-if="field_map.form_id == 'custom'"
                                    :class="{
                                        'validate_error': (form_data_validate.fields_map[key] != undefined && form_data_validate.fields_map[key].form_text == false)
                                    }"
                                >
                                    <input class="form-control" 
                                           type="text" 
                                           v-model="field_map.form_text" 
                                           placeholder="Enter text"
                                           required />
                                </div> 

                                <div class="settings_integration_maping_delete">
                                    <!-- <button>
                                        <i class="ni ni-fat-remove text-red"></i>
                                    </button>     -->
                                    <base-button type="danger" size="sm"
                                        @click="removeMapingField(field_map, key)"
                                    >
                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAABFUlEQVR4nO2WMWrDMBhGRYcMyepsGUIO4D1Q6AGypKfKQXKHbg7OEVo6BkKHdO+W7RWFL1Q4toks2SWgtwhJlr8n/t9gYxIJT4BX4Jtb7Nra9A314VdOQwhcuHc9OCgWjydgCTocoywkARFrHlwCkoBIJTDtPXH4zyY8ADNn/jO0wEzjk8allRhMwAK8AJ/AXPOlqQBkQAHk1b0YAm9aPl4lasLf9UzRh8AY2GnrC1g4e1Mn3I6Zl8C91El4h4d+RsAE2Dvl+PAKt9h/PB16NuESSCLzecEGf87ASuenzs0bG7MRYCSJU8dwt+b7ThJdqNz8UvO2ryMqbd0uiaJXCf4CartdjVnqmbIPgVwSjd0uia3thV8lRoRQg3LbwwAAAABJRU5ErkJggg==">    
                                    </base-button>                                
                                </div> 

                                
                            </div>

                        <div class="settings_integration_button_wrap">                            
                            <base-button
                                type="primary"
                                size="sm"
                                class="my-4"
                                @click="addFieldsMap()"
                            >
                                Add maping fields
                            </base-button>
                        </div>
                        <div class="settings_integration_button_wrap">                            
                            <base-button type="warning" @click="save()">
                                Save
                            </base-button>
                            <base-button type="success" @click="saveAndActivate()">
                                Save and activate
                            </base-button>
                        </div>
                    </div>
                    <base-alert type="warning" class="mt-5" v-if="error_message != '' && form_data.form_ids.length > 0">
                        <strong>Error!</strong> {{error_message}}
                    </base-alert>
                </div>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </template>
  <style src="@vueform/multiselect/themes/default.css"></style>
  <script>  
    import { mapGetters } from "vuex";
    import apiService from "@/services/api";
    import Multiselect from '@vueform/multiselect';
//   import TableIntegrations from "./Tables/TableIntegrations";
  export default {
    name: "settings",
    components: {
        Multiselect,
    //   TableIntegrations,
    },
    props: {
        type: {
        type: String,
        },
    },
    data() {
    return {
        integration_id: null,
        fb_pages: [],
        fb_forms: [],
        keepin_info: {
            deal: {
                statuses: [],
                funnels: [],
                stages: [],
                fields: [],
            },
            contact: {
                statuses: [],
                fields: [],
            },
            lead: {
                fields: [],
            },
            sources: [],
            users: [],
        },
        fb_fields: [],
        keepin_fields: [],
        form_data: {
            page_id: null,
            form_ids: [],
            pipeline_id: null,
            deal_name: null,
            deal_amount: null,
            deal_currency: null,
            stage_id: null,
            responsible_id: null,
            source_id: null,
            create_lead: false,
            fields_map: []
            
        },
        form_data_validate: {},
        error_message: ''
    };
  },
  
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  created() {
    this.setIdIntegrations();
    this.getInfo();
    this.createFormDataValidate();
    console.log('user', this.user);
  },
  methods: {
    setIdIntegrations() {
      this.integration_id = this.$route.params.integrations_exports_id;
    },
    saveAndActivate(){
        this.createFormDataValidate();
        let me = this;
        me.error_message = '';
        if (this.form_data.page_id == null){
            this.form_data_validate.page_id = false;
            this.error_message = 'Select facebook page';
            return true;
        }
        if (this.form_data.form_ids.length == 0){            
            this.form_data_validate.form_ids = false;
            this.error_message = 'Select facebook form';
            return true;
        }
        if (this.form_data.fields_map.length == 0){
            this.error_message = 'Create mapping fields';
            return true;
        }

        this.form_data.fields_map.forEach(function(item, key) {
            if (item.crm_id == null){
                me.form_data_validate.fields_map[key].crm_id = false;
                me.error_message = 'Keepin field can not be empty in mapping fields.';
                return true;
            }
            if (item.form_id == null && item.type_select == 'facebook'){
                me.form_data_validate.fields_map[key].form_id = false;
                me.error_message = 'Field can not be empty in mapping fields.';
                return true;
            }

            if (item.form_id == 'custom' && item.type_select == 'facebook' && item.form_text == ''){
                me.form_data_validate.fields_map[key].form_text = false;
                me.error_message = 'Text field can not be empty in mapping fields.';
                return true;
            }
        });
        let collectionOfData = this.collectionOfData();
        collectionOfData.activate = true;
        console.log('collectionOfData', collectionOfData);

        apiService
        .saveIntegrationSettings(collectionOfData)
        .then((response) => {
          console.log("saveIntegrationSettings response ", response)
          this.$router.push({ name: "integrations" });
        //   this.services = response.data.data.services;
        //   this.integrations = response.data.data.integrations;
        //   console.log("services: ", this.services)
        });
    },
    save(){
        let collectionOfData = this.collectionOfData();
        console.log('collectionOfData', collectionOfData);

        apiService
        .saveIntegrationSettings(collectionOfData)
        .then((response) => {
          console.log("saveIntegrationSettings response ", response)
        //   this.services = response.data.data.services;
        //   this.integrations = response.data.data.integrations;
        //   console.log("services: ", this.services)
        });
        
    },
    selectFacebookMapingField(field_map, key){
        console.log('select fb', key, this.form_data.fields_map);
        // if ()
    },
    deselectFacebookMapingField(field_map, key){
        console.log('deselect fb', key, this.form_data.fields_map);
    },
    selectKeepinMapingField(field_map, key){
        let me = this;
        let array_of_crm_id = [null, null];
        this.form_data.fields_map[key].options = [];

        if (field_map.crm_id != null){
            array_of_crm_id = field_map.crm_id.split('_');
            if (array_of_crm_id[0] !== null && array_of_crm_id[1] !== null){
                let crm_type = array_of_crm_id[0];
                let crm_id = array_of_crm_id[1];
                this.keepin_info[crm_type].fields.forEach(function(item) {
                    if (item.id == crm_id){
                        console.log(' -------------------select item', item);
                        me.form_data.fields_map[key].crm_field_type = item.type;
                        me.form_data.fields_map[key].crm_field_name = item.name;
                        if (item.field_type == "list"){
                            if (item.options.length > 0){
                                item.options.forEach(function(option) {
                                    me.form_data.fields_map[key].options.push({
                                        text: option, 
                                        label: option
                                    });
                                    
                                });
                            }
                            // field_map.options = item.options;
                            me.form_data.fields_map[key].type_select = 'crm';
                            me.form_data.fields_map[key].form_id = null;
                            me.form_data.fields_map[key].form_text = '';
                        } else {
                            me.form_data.fields_map[key].type_select = 'facebook';
                            me.form_data.fields_map[key].form_id = null;
                            me.form_data.fields_map[key].form_text = '';
                            me.form_data.fields_map[key].options = [];
                        }
                    }
                });
            }
        }
        
        console.log('select', key, this.form_data.fields_map);

    },
    deselectKeepinMapingField(field_map, key){
        this.form_data.fields_map[key] = {
            "form_id": null,
            "crm_id": null,
            "form_text": '',
            "type_select": 'facebook',
            "options": [],
            "crm_field_type": null,
            "crm_field_name": null,
        };
        console.log('deselect', key, this.form_data.fields_map);
    },
    collectionOfData(activate = false){
        let fields_map = [];
        let lead_forms = [];
        let me = this;

        if (this.form_data.fields_map.length > 0){
            this.form_data.fields_map.forEach(function(item) {
                let array_of_crm_id = [null, null];
                let static_value = null;

                if (item.crm_id != null){
                    array_of_crm_id = item.crm_id.split('_');
                }
                if (item.form_id == 'custom' && item.type_select == 'facebook'){
                    static_value = item.form_text;
                } else if (item.type_select == 'crm'){
                    static_value = item.form_id;
                }

                fields_map.push({
                    "type": item.crm_field_type,
                    "entity": array_of_crm_id[0],
                    "form_id": (item.form_id != 'custom' && item.type_select == 'facebook') ? item.form_id : null,
                    "crm_id": item.crm_field_name,
                    "static_value": static_value,
                });
                
            });
        }
        
        let main_responsible_name = null;
        if (this.form_data.responsible_id != null){
            this.keepin_info.users.forEach(function(item) {

                if (item.id == me.form_data.responsible_id){
                    main_responsible_name = item.name;
                }
                
            });
        }

        if (this.form_data.form_ids.length > 0){
            this.form_data.form_ids.forEach(function(form_id) {
                me.fb_forms.forEach(function(fb_form) {
                    if (fb_form.lead_form_id == form_id){
                        lead_forms.push({
                            "lead_form_id": fb_form.lead_form_id,
                            "status": (activate) ? "active" : "inactive",
                            "is_active": activate 
                        });
                    }
                });               
                
            });
        }

        let keepin_data = {
            "id": this.integration_id,
            "auth_id": null, //on router
            "ba_id": this.user.currentBusinessAccount.id,
            "stage_id": this.form_data.stage_id,
            "source_id": this.form_data.source_id,
            "title": this.form_data.deal_name,
            "total": this.form_data.deal_amount,
            "currency": this.form_data.deal_currency,
            "lead_status": this.form_data.create_lead,
            "main_responsible_id": this.form_data.responsible_id,
            "main_responsible_name": main_responsible_name,
            "pipeline_id": this.form_data.pipeline_id,
            "fields_map": fields_map
        };

        let facebook_data = {
            "config_id": this.integration_id,
            "ba_id": this.user.currentBusinessAccount.id,
            "page_id": this.form_data.page_id,
            "created_by": this.user.id, 
            "auth_id": null, //on router
            "delivery_service_id": null, // on router
            "status": (activate) ? "active" : "inactive",
            "is_active": activate, 
            "lead_forms": lead_forms
        };

        let request_data = {
            'keepin_data': keepin_data,
            'facebook_data': facebook_data,
            'config': this.form_data,
            'integration_id': this.integration_id
        }
        return request_data;
    },
    removeMapingField(field_map, index){
        this.form_data.fields_map.splice(index, 1);
    },
    keepinFieldsCollection(){
        this.keepin_fields = [];
        let deal_fields = [];
        let contact_fields = [];
        let lead_fields = [];

        if (this.keepin_info.lead.fields.length > 0){
            this.keepin_info.lead.fields.forEach(function(item) {
                lead_fields.push({
                    id: 'lead_'+item.id, 
                    label: item.label,
                    // name: item.name,
                    // field_type: item.field_type,
                    // type: item.type,
                    // options: item.options,
                });
            });
            this.keepin_fields.push({
                label: 'Lead:',
                options: lead_fields,
            });
        }

        if (this.keepin_info.contact.fields.length > 0){

            this.keepin_info.contact.fields.forEach(function(item) {
                contact_fields.push({
                    id: 'contact_'+item.id, 
                    label: item.label
                });
            });
            this.keepin_fields.push({
                label: 'Contact:',
                options: contact_fields,
            });

        }

        if (this.keepin_info.deal.fields.length > 0){
            this.keepin_info.deal.fields.forEach(function(item) {
                deal_fields.push({
                    id: 'deal_'+item.id, 
                    label: item.label
                });
            });
            this.keepin_fields.push({
                label: 'Deal:',
                options: deal_fields,
            });
        }

        console.log('keepin_fields', this.keepin_fields);
    },
    addFieldsMap(){
        let data = {
            "form_id": null,
            "crm_id": null,
            "form_text": '',
            "type_select": 'facebook',
            "form_options": [],
        };
        this.form_data.fields_map.push(data);
    },
    getInfo(){
        apiService
        .getIntegrationInfo(this.user.id, this.integration_id)
        .then((response) => {
            console.log("response "+this.integration_id, response)
            
            if (response.data.keepin != undefined && response.data.keepin != null){
                this.addKeepinInfo(response.data.keepin);
            }

            if (response.data.fb_lf_page != null && response.data.fb_lf_page.data != undefined && response.data.fb_lf_page.data != null){
                this.fb_pages = JSON.parse(JSON.stringify(response.data.fb_lf_page.data));
            }

            if (response.data.fb_lf_forms != null && response.data.fb_lf_forms.data != undefined && response.data.fb_lf_forms.data != null){
                this.fb_forms = JSON.parse(JSON.stringify(response.data.fb_lf_forms.data));
            }
            
        });
        
    },
    getForms(){
        apiService
        .getIntegrationFbForms(this.user.id, this.integration_id, this.form_data.page_id)
        .then((response) => {
          console.log("response getForms "+this.integration_id, response)
            let fb_forms_request = response.data;
            if (fb_forms_request.data.forms != undefined){
                this.fb_forms = fb_forms_request.data.forms;
            }

            if (fb_forms_request.data.questions != undefined){
                let me = this;
                this.fb_fields = [{text: 'custom', label: 'Create custom'}];

                fb_forms_request.data.questions.forEach(function(item) {
                    me.fb_fields.push({
                        text: item, 
                        label: item + ' (from facebook)'
                    });
                });

                // this.fb_fields = fb_forms_request.data.questions;

                // this.fb_fields.unshift({text: 'Custom', label: 'Create custom'});
            }
        });
        console.log('form_data', this.form_data);
        
        
    },
    addKeepinInfo(keepin_data_request = null){
        keepin_data_request = { // дані, які приходять в запиті з бекенда
            "basic fields": {
                "pipeline_name": {
                    "title": "Назва воронки",
                    "type": "string"
                },
                "title": {
                    "title": "Назва угоди",
                    "type": "string"
                },
                "total": {
                    "title": "Сума угоди",
                    "type": "integer"
                },
                "currency": {
                    "title": "Валюта",
                    "type": "string"
                },
                "stage_id": {
                    "title": "ID етапу угоди",
                    "type": "integer"
                },
                "stage_name": {
                    "title": "Назва етапу угоди",
                    "type": "string"
                },
                "source_id": {
                    "title": "ID джерела угоди",
                    "type": "integer"
                },
                "source_name": {
                    "title": "Назва джерела угоди",
                    "type": "string"
                },
                "lead_status": {
                    "title": "Статус ліда",
                    "type": "boolean"
                },
                "main_responsible_id": {
                    "title": "ID головного відповідального",
                    "type": "integer"
                },
                "main_responsible_name": {
                    "title": "Назва головного відповідального",
                    "type": "string"
                },
                "fields_map": [
                    {
                        "type": "system|custom",
                        "entity": "contact|lead",
                        "form_id": "name",
                        "crm_id": "person"
                    }
                ]
            },
            "agreement": {
                "statuses": null,
                "funnels": [
                    {
                        "id": 1,
                        "title": "Продаж"
                    }
                ],
                "stages": [
                    {
                        "id": 15,
                        "name": "Повернення"
                    },
                    {
                        "id": 14,
                        "name": "Отримано"
                    },
                    {
                        "id": 13,
                        "name": "Очікує у відділенні"
                    },
                    {
                        "id": 12,
                        "name": "Відправлено у службу доставки"
                    },
                    {
                        "id": 11,
                        "name": "Кваліфіковано"
                    },
                    {
                        "id": 10,
                        "name": "Підбір матеріалу"
                    },
                    {
                        "id": 6,
                        "name": "Успішно завершено"
                    },
                    {
                        "id": 2,
                        "name": "Нова"
                    }
                ],
                "fields": [
                    {
                        "id": 3464112,
                        "name": "title",
                        "field_type": "string",
                        "type": "system",
                        "label": "Назва",
                        "options": []
                    },
                    {
                        "id": 3464129,
                        "name": "comment",
                        "field_type": "text",
                        "type": "system",
                        "label": "Коментар",
                        "options": []
                    },
                    {
                        "id": 3464495,
                        "name": "adriesa_219",
                        "field_type": "string",
                        "type": "custom",
                        "label": "Адреса",
                        "options": []
                    },
                    {
                        "id": 3957881,
                        "name": "vakansiia_247",
                        "field_type": "string",
                        "type": "custom",
                        "label": "Вакансія",
                        "options": []
                    },
                    {
                        "id": 3464492,
                        "name": "sieriia_i_nomier_pasport_3464492",
                        "field_type": "string",
                        "type": "custom",
                        "label": "Серія і номер паспорта",
                        "options": []
                    },
                    {
                        "id": 3464493,
                        "name": "tip_zvierniennia_217",
                        "field_type": "string",
                        "type": "custom",
                        "label": "Тип звернення",
                        "options": []
                    },
                    {
                        "id": 3464494,
                        "name": "tarif_218",
                        "field_type": "list",
                        "type": "custom",
                        "label": "Тариф",
                        "options": [
                            "тариф 1",
                            "тариф 2"
                        ]
                    },
                    {
                        "id": 3957880,
                        "name": "ipn_246",
                        "field_type": "string",
                        "type": "custom",
                        "label": "ІПН",
                        "options": []
                    },
                    {
                        "id": 3957882,
                        "name": "adriesa_rieiestratsiyi_248",
                        "field_type": "string",
                        "type": "custom",
                        "label": "Адреса реєстрації",
                        "options": []
                    },
                    {
                        "id": 4011940,
                        "name": "list_250",
                        "field_type": "list",
                        "type": "custom",
                        "label": "list",
                        "options": [
                            "1",
                            "2",
                            "3",
                            "4"
                        ]
                    },
                    {
                        "id": 4461997,
                        "name": "iakas_data_369",
                        "field_type": "date",
                        "type": "custom",
                        "label": "Якась дата",
                        "options": []
                    },
                    {
                        "id": 4997210,
                        "name": "multispisok_381",
                        "field_type": "custom_reference",
                        "type": "custom",
                        "label": "Мультисписок",
                        "options": []
                    }
                ]
            },
            "client": {
                "statuses": [
                    {
                        "id": 8,
                        "name": "Партнер"
                    },
                    {
                        "id": 7,
                        "name": "VIP"
                    },
                    {
                        "id": 6,
                        "name": "Новий"
                    },
                    {
                        "id": 2,
                        "name": "Постійний замовник"
                    }
                ],
                "fields": [
                    {
                        "id": 3464185,
                        "name": "phones",
                        "field_type": "custom",
                        "type": "system",
                        "label": "Телефон",
                        "options": []
                    },
                    {
                        "id": 3464186,
                        "name": "emails",
                        "field_type": "custom",
                        "type": "system",
                        "label": "Email",
                        "options": []
                    },
                    {
                        "id": 3464184,
                        "name": "fullname",
                        "field_type": "string",
                        "type": "system",
                        "label": "ПІБ",
                        "options": []
                    },
                    {
                        "id": 3464187,
                        "name": "posada_1",
                        "field_type": "list",
                        "type": "custom",
                        "label": "Посада",
                        "options": [
                            "керівник",
                            "співробітник",
                            "ЛПР",
                            "менеджер"
                        ]
                    },
                    {
                        "id": 4397407,
                        "name": "birthday",
                        "field_type": "date",
                        "type": "system",
                        "label": "День народження",
                        "options": []
                    }
                ]
            },
            "lead": {
                "fields": [
                    {
                        "id": 3464152,
                        "name": "company",
                        "field_type": "string",
                        "type": "system",
                        "label": "Назва компанії",
                        "options": []
                    },
                    {
                        "id": 3464153,
                        "name": "person",
                        "field_type": "string",
                        "type": "system",
                        "label": "Контактна особа",
                        "options": []
                    },
                    {
                        "id": 3464154,
                        "name": "phones",
                        "field_type": "custom",
                        "type": "system",
                        "label": "Номери телефонів",
                        "options": []
                    },
                    {
                        "id": 3464155,
                        "name": "emails",
                        "field_type": "custom",
                        "type": "system",
                        "label": "Email",
                        "options": []
                    },
                    {
                        "id": 3464160,
                        "name": "users",
                        "field_type": "custom",
                        "type": "system",
                        "label": "Відповідальні",
                        "options": []
                    },
                    {
                        "id": 3464164,
                        "name": "comment",
                        "field_type": "text",
                        "type": "system",
                        "label": "Коментар",
                        "options": []
                    },
                    {
                        "id": 3464169,
                        "name": "birthday",
                        "field_type": "date",
                        "type": "system",
                        "label": "День народження",
                        "options": []
                    },
                    {
                        "id": 3464149,
                        "name": "tags",
                        "field_type": "custom",
                        "type": "system",
                        "label": "Теги",
                        "options": []
                    }
                ]
            },
            "sources": [
                {
                    "id": 4,
                    "name": "Email"
                },
                {
                    "id": 3,
                    "name": "Власні контакти"
                },
                {
                    "id": 2,
                    "name": "Рекомендації клієнтів"
                },
                {
                    "id": 1,
                    "name": "Реклама"
                }
            ],
            "users": [
                {
                    "id": 1,
                    "name": "Adminka Test",
                    "email": "info@adminka.pro"
                }
            ]
        };
        if (keepin_data_request.agreement != undefined){
            let deal = keepin_data_request.agreement;
            if (deal.statuses != undefined){
                this.keepin_info.deal.statuses = deal.statuses;
            }
            if (deal.funnels != undefined){
                this.keepin_info.deal.funnels = deal.funnels;
            }
            if (deal.stages != undefined){
                this.keepin_info.deal.stages = deal.stages;
            }
            if (deal.fields != undefined){
                this.keepin_info.deal.fields = deal.fields;
            }
        }
        if (keepin_data_request.client != undefined){
            let contact = keepin_data_request.client;
            if (contact.statuses != undefined){
                this.keepin_info.contact.statuses = contact.statuses;
            }
            if (contact.fields != undefined){
                this.keepin_info.contact.fields = contact.fields;
            }
        }
        if (keepin_data_request.lead != undefined){
            if (keepin_data_request.lead.fields != undefined){
                this.keepin_info.lead.fields = keepin_data_request.lead.fields;
            }
        }
        if (keepin_data_request.sources != undefined){
            this.keepin_info.sources = keepin_data_request.sources;
        }
        if (keepin_data_request.users != undefined){
            this.keepin_info.users = keepin_data_request.users;
        }
        this.keepinFieldsCollection();
        console.log('keepin_info', this.keepin_info);
    },
    resetFormData(){
        this.form_data = {
            page_id: null,
            form_ids: [],
            pipeline_id: null,
            deal_name: null,
            deal_amount: null,
            deal_currency: null,
            stage_id: null,
            responsible_id: null,
            source_id: null,
            create_lead: false,
            fields_map: []
        }
    },
    createFormDataValidate(){
        let me = this;
        for (const key in this.form_data) {
            if (key == 'fields_map'){
                me.form_data_validate[key] = me.form_data.fields_map.map(() => ({ 
                    form_id: true,
                    crm_id: true,
                    form_text: true,
                }));
            } else {
                me.form_data_validate[key] = true;
            }
            
        }
        console.log('form_data_validate', this.form_data_validate);
    }
  },
  watch: {
  }
  };
  </script>
  <style></style>
  