<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <!--        <div class="card-header bg-transparent pb-5">-->
        <!--          <div class="text-muted text-center mt-2 mb-3">-->
        <!--            <small>Sign in with</small>-->
        <!--          </div>-->
        <!--          <div class="btn-wrapper text-center">-->
        <!--            <a href="#" class="btn btn-neutral btn-icon">-->
        <!--              <span class="btn-inner&#45;&#45;icon"-->
        <!--                ><img src="img/icons/common/github.svg"-->
        <!--              /></span>-->
        <!--              <span class="btn-inner&#45;&#45;text">Github</span>-->
        <!--            </a>-->
        <!--            <a href="#" class="btn btn-neutral btn-icon">-->
        <!--              <span class="btn-inner&#45;&#45;icon"-->
        <!--                ><img src="img/icons/common/google.svg"-->
        <!--              /></span>-->
        <!--              <span class="btn-inner&#45;&#45;text">Google</span>-->
        <!--            </a>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Sign in to your account</small>
          </div>
          <form role="form" @submit.prevent="handleSubmit">
            <base-input formClasses="input-group-alternative mb-3" placeholder="Email" addon-left-icon="ni ni-email-83"
              v-model="model.email" :valid="validate.email.isValid" :error="validate.email.error">
            </base-input>

            <div class="input-group input-group-alternative mb-3">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
              </div>
              <input :type="showPassword ? 'text' : 'password'" class="form-control" placeholder="Password"
                v-model="model.password" :class="{ 'is-invalid': validate.password.error }">
              <div class="input-group-append">
                <span class="input-group-text" @click="togglePasswordVisibility">
                  <i :class="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                </span>
              </div>
              <div v-if="validate.password.error" class="text-danger invalid-feedback"
                style="display: block;padding-left: 15px;padding-bottom: 7px;margin-top: 0.5rem;">
                {{ validate.password.error }}
              </div>
            </div>


            <!--            <base-checkbox class="custom-control-alternative">-->
            <!--              <span class="text-muted">Remember me</span>-->
            <!--            </base-checkbox>-->
            <div class="text-center">
              <base-button type="primary" class="my-4" @click.prevent="handleSubmit">
                Sign in
              </base-button>
            </div>
            <div class="text-danger font-italic mt-2 text-center">
              <small>{{ formSubmitError }}</small>
            </div>
          </form>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-6">
          <router-link to="/password-reset-request" class="text-light">
            <small>Forgot password?</small>
          </router-link>
        </div>
        <div class="col-6 text-right">
          <router-link to="/register" class="text-light"><small>Registration</small></router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import lStorage from "@/services/localStorage";
import store from "@/store";
import apiService from "@/services/api";
export default {
  name: "login",
  data() {
    return {
      model: {
        email: "",
        password: "",
      },
      validate: {
        email: {
          isValid: null,
          error: "",
          typeValid: [
            {
              type: "required",
              errorMess: "The field must not be empty",
            },
            {
              type: "email",
              errorMess: "Email not valid",
            },
          ],
        },
        password: {
          isValid: null,
          error: "",
          typeValid: [
            {
              type: "required",
              errorMess: "The field must not be empty",
            },
          ],
        },
      },
      formIsValid: true,
      formSubmitError: "",
      showPassword: false,
    };
  },
  watch: {
    "model.email"() {
      this.resetErrors("email");
    },
    "model.password"() {
      this.resetErrors("password");
    },
  },
  methods: {
    resetErrors(type) {
      this.validate[ type ].isValid = null;
      this.validate[ type ].error = "";
      this.formSubmitError = "";
    },
    handleSubmit() {
      this.validateForm();
      if (this.formIsValid) {
        apiService
          .login({
            email: this.model.email,
            password: this.model.password,
          })
          .then((response) => {
            let data = response.data.data;
            console.log('DATA', data);
            if (response.data.success) {
              if (data.token != null && data.token != undefined) {
                lStorage.setObject("user", data.user);
                lStorage.set("jwt", data.token);
                store.commit("user/addUser", data.user);
                store.commit("user/addToken", data.token);
                if (this.$route.params.nextUrl != null) {
                  this.$router.push(this.$route.params.nextUrl);
                } else {
                  this.$router.push({ name: "dashboard" });
                }
              } else {
                this.formSubmitError = "Error login!";
              }
            }
          })
          .catch((error) => {
            if (error.response) {
              console.log(error.response);
              this.formSubmitError = error.response.data.message;
            }
          });
      } else {
        return console.log("form not valid");
      }
    },
    validateForm() {
      this.formIsValid = true;
      let errorMess = "";
      for (let key in this.validate) {
        this.validate[ key ][ "error" ] = "";

        this.validate[ key ][ "typeValid" ].forEach((typeValid) => {
          if (typeValid.type === "required") {
            this.validate[ key ][ "isValid" ] = this.model[ key ] !== "";
            errorMess = typeValid.errorMess;
          }
          if (this.validate[ key ][ "isValid" ] && typeValid.type === "email") {
            this.validate[ key ][ "isValid" ] = this.validateEmail(this.model[ key ]);
            errorMess = typeValid.errorMess;
          }
        });

        if (!this.validate[ key ][ "isValid" ]) {
          this.formIsValid = false;
          this.validate[ key ][ "error" ] = errorMess;
        }
      }
    },
    validateEmail(email) {
      let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      return re.test(String(email).toLowerCase());
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

  },
};
</script>
<style>
</style>
