<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Enter your new password</small>
          </div>
          <form role="form" @submit.prevent="confirmReset">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="New Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="password"
            ></base-input>
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Confirm Password"
              type="password"
              addon-left-icon="ni ni-lock-circle-open"
              v-model="confirmPassword"
            ></base-input>
            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                @click.prevent="confirmReset"
              >
                Confirm Reset
              </base-button>
            </div>
            <div class="text-danger font-italic mt-2 text-center">
              <small>{{ formSubmitMessage }}</small>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/api";
export default {
  data() {
    return {
      email: "",
      password: "",
      confirmPassword: "",
      token: "", // this will be obtained from the URL
      formSubmitMessage: "",
    };
  },
  mounted() {
    // Get token from URL
    const urlParams = new URLSearchParams(window.location.search);
    this.token = this.$route.query.token;
    this.email = urlParams.get("email") || "";
  },
  methods: {
    confirmReset() {
      if (this.password !== this.confirmPassword) {
        this.formSubmitMessage = "Passwords do not match.";
        return;
      }
      apiService
        .resetPasswordConfirm({
          password: this.password,
          token: this.token,
          email: this.email,
          password_confirmation: this.confirmPassword,
        })
        .then((response) => {
          this.formSubmitMessage = response.data.message;
          setTimeout(() => {
            this.$router.push({ name: 'login' });
        }, 2000);
        })
        .catch((error) => {
          this.formSubmitMessage = "Error: Could not confirm reset.";
          console.error(error); // Log the error for debugging purposes
        });
    },
  },
};
</script>
