<template>
  <select class="custom-select" v-model="account_index_selected">
    <option :value="-1" selected>Select a Business account</option>
    <option
        v-for="(account, index) in business_accounts"
        :value="index"
        v-bind:key="index"
    >
      {{ account.name }} ({{ account.path }})
    </option>
  </select>
</template>

<script>
  import apiService from "../../services/api";
  import lStorage from "@/services/localStorage";

  export default {
    name: "SelectBA",
    props: {
      user_id: {
        type: Number,
        require: true,
      },
      service_path: {
        type: String,
        require: true,
      },
    },
    emits: ['selectedBA'],
    data() {
      return {
        account_index_selected: -1,
        business_accounts: [],
      };
    },
    watch: {
      account_index_selected(val) {
        console.log("account_index_selected val", val);
        if (val === -1){
          this.$emit("selectedBA", {id: null});
        } else {
          this.$emit("selectedBA", this.business_accounts[val]);
        }
      },
      user_id(val) {
        if(this.service_path) {
          this.getBusinessAccounts(this.service_path, val);
        }
      },
      // service_path(val) {
      //   if(val) {
      //     this.getBusinessAccounts(val, this.user_id).then(() => {
      //       if (this.account_index_selected === -1){
      //         this.$emit("selectedBA", {id: null});
      //       } else {
      //         this.$emit("selectedBA", this.business_accounts[this.account_index_selected]);
      //       }
      //     });
      //   }
      // },
    },
    mounted() {
      // if(this.service_path){
      //   this.getBusinessAccounts(this.service_path, this.user_id).then(() => {
      //     this.$emit("getBusinessAccounts", this.business_accounts[this.account_index_selected]);
      //   });
      // }
      this.getBusinessAccounts(this.service_path, this.user_id).then(() => {
        let user = lStorage.getObject("user");
        let currentIndex = -1;

        if (user && user.currentBusinessAccount) {
          // Find the index of the currentBusinessAccount
          currentIndex = this.business_accounts.findIndex(account => 
            account.id === user.currentBusinessAccount.id
          );
        }

        // If currentBusinessAccount is not set, but there are accounts, select the first one
        if (currentIndex === -1 && this.business_accounts.length > 0) {
          currentIndex = 0; // Select the first account
        }

        // Set the default selection
        this.account_index_selected = currentIndex;

        // Emit an event with the selected account or null if none
        if (currentIndex !== -1) {
          this.$emit("selectedBA", this.business_accounts[currentIndex]);
        } else {
          this.$emit("selectedBA", { id: null });
        }
      });
    },
    methods: {
      getBusinessAccounts(service_path, user_id) {
        return apiService
          .getBusinessAccountsByUserId(user_id)
          .then((response) => {
            console.log("getBusinessAccountsByUserId", response);
            this.business_accounts = response.data.data;
            return response;
          });
      },
    }
  }
</script>

<style scoped>

</style>
