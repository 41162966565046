<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar
      :background-color="sidebarBackground"
      short-title="Adminka"
      title="Adminka"
    >
      <template v-slot:links>
        <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: { name: 'dashboard'},
          }"
        />

        <sidebar-item
            :link="{
            name: 'Data Export',
            icon: 'ni ni-archive-2 text-primary',
            path: { name: 'data_export'},
          }"
        />

        <sidebar-item
            :link="{
            name: 'Integrations',
            icon: 'ni ni-spaceship text-red',
            path: { name: 'integrations'},
          }"
        />

        <div
            v-if="user.role === 'admin'"
        >
          <li class="nav-item">
            <a href="#"
               class="nav-link"
               @click="toggleMenuAdmin()"
            >
              <i class="ni ni-collection text-info"></i>
              <span class="nav-link-text">Admin</span>
            </a>
          </li>

          <div class=""
               v-show="view_menu_admin"
          >
            <sidebar-item
                :link="{
                  name: 'Users',
                  icon: 'ni ni-bold-right text-red',
                  path: { name: 'admin_users'},
                }"
                :class="['nav-item-admin']"
            />
            <sidebar-item
                :link="{
                  name: 'Business accounts',
                  icon: 'ni ni-bold-right text-red',
                  path: { name: 'admin_ba'},
                }"
                :class="['nav-item-admin']"
            />
          </div>
        </div>





<!--        <sidebar-item-->
<!--          :link="{-->
<!--            name: 'Icons',-->
<!--            icon: 'ni ni-planet text-blue',-->
<!--            path: { name: 'icons'},-->
<!--          }"-->
<!--        />-->
<!--        <sidebar-item-->
<!--          :link="{-->
<!--            name: 'Maps',-->
<!--            icon: 'ni ni-pin-3 text-orange',-->
<!--            path: { name: 'maps'},-->
<!--          }"-->
<!--        />-->
<!--        <sidebar-item-->
<!--          :link="{-->
<!--            name: 'User Profile',-->
<!--            icon: 'ni ni-single-02 text-yellow',-->
<!--            path: { name: 'profile'},-->
<!--          }"-->
<!--        />-->
<!--        <sidebar-item-->
<!--          :link="{-->
<!--            name: 'Tables',-->
<!--            icon: 'ni ni-bullet-list-67 text-red',-->
<!--            path: { name: 'tables'},-->
<!--          }"-->
<!--        />-->
<!--        <sidebar-item-->
<!--          :link="{-->
<!--            name: 'Login',-->
<!--            icon: 'ni ni-key-25 text-info',-->
<!--            path: { name: 'login'},-->
<!--          }"-->
<!--        />-->
<!--        <sidebar-item-->
<!--          :link="{-->
<!--            name: 'Register',-->
<!--            icon: 'ni ni-circle-08 text-pink',-->
<!--            path: { name: 'register'},-->
<!--          }"-->
<!--        />-->
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar" class="container-dashboard">
        <!-- your content here -->
        <router-view  class="container-content"></router-view>
        <content-footer v-if="!$route.meta.hideFooter" class="container-footer"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import {mapGetters} from "vuex";
import lStorage from "../services/localStorage";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
  },
  data() {
    return {
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
      view_menu_admin: false
    };
  },
  watch: {
    view_menu_admin(val){
      lStorage.set('main_menu:view_menu_admin', val)
    }
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    toggleMenuAdmin(){
      this.view_menu_admin = !this.view_menu_admin;
    },
    setWiewMenuAdmin(){
      let view_menu_admin = lStorage.get('main_menu:view_menu_admin');
      if (view_menu_admin !== null && view_menu_admin !== undefined && view_menu_admin == 'true'){
        this.view_menu_admin = true;
      }
    }
  },
  created() {
    console.log("user DashboardLayuout", this.user);
    this.setWiewMenuAdmin();
  },
};
</script>
<style lang="scss"></style>
