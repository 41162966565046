// import lStorage from "@/services/localStorage";
import router from "@/router";
import store from "../store";
import http from "axios";
import userService from "./user";

const baseUrl = process.env.VUE_APP_BACKEND_URL;

const ifErrLogout = function (error) {
  console.error(error);
  if (error.response && error.response.status === 401) {
    userService.logout();
  }
  if (error.response && error.response.status === 403) {
    router.push({ name: "dashboard" });
  }
};

const getUsers = function (params) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  let params_url = new URLSearchParams(params).toString();
  return http
    .get(baseUrl + "/api/admin/users?" + params_url, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getBusinessAccounts = function (page) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .get(baseUrl + "/api/admin/business-accounts?page=" + page, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getBusinessAccountInfo = function (params) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  let params_url = new URLSearchParams(params).toString();
  return http
    .get(baseUrl + "/api/admin/business-account?" + params_url, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getBAServices = function (data) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .get(
      baseUrl +
        "/api/admin/business-account/services?user_id=" +
        data.user_id +
        "&ba_id=" +
        data.ba_id,
      {
        headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const startSync = function (service_path, data) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  let url = baseUrl + "/api/admin/" + service_path + "/start-sync";
  return http
    .post(url, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const startSyncAccount = function (service_path, data) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  let url = baseUrl + "/api/admin/" + service_path + "/start-sync-account";
  return http
    .post(url, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const adminService = {
  getUsers,
  getBusinessAccounts,
  getBusinessAccountInfo,
  getBAServices,
  startSync,
  startSyncAccount,
};

export default adminService;
