<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <table-exports title="Data Exports" btn="Add Export"></table-exports>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import TableExports from "./Tables/TableExports";
export default {
  name: "tables",
  components: {
    TableExports,
  },
};
</script>
<style></style>
