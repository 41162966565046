<template>
  <select class="custom-select" v-model="integration_index_selected">
    <option :value="-1" selected>Select an Integration</option>
    <option
        v-for="(account, index) in integrations"
        :value="index"
        v-bind:key="index"
    >
      {{ account.name }}
    </option>
  </select>
</template>

<script>
  import apiService from "../../services/api";

  export default {
    name: "SelectIntegrations",
    props: {
      user_id: {
        type: Number,
        require: true,
      },
      service_path: {
        type: String,
        require: true,
      },
      ba_id: {
        type: Number,
        require: true,
      },
    },
    emits: ['selectedIntegrations'],
    data() {
      return {
        integration_index_selected: -1,
        integrations: [],
      };
    },
    watch: {
      integration_index_selected(val) {
        console.log("integration_index_selected val", val);
        if (val === -1){
          this.$emit("selectedIntegrations", {id: null});
        } else {
          this.$emit("selectedIntegrations", this.integrations[val]);
        }
      },
      user_id(val) {
        if(this.service_path) {
          this.getIntegrations(this.service_path, val, this.ba_id);
        }
      },
      service_path(val) {
        if (val) {
          this.getIntegrations(val, this.user_id, this.ba_id).then(() => {
            if (this.integration_index_selected === -1){
              this.$emit("selectedIntegrations", {id: null});
            } else {
              if (this.integrations == undefined || this.integrations[this.integration_index_selected] == undefined){    
                console.log('this.integrations[this.integration_index_selected]', this.integrations[this.integration_index_selected]);          
                this.zeroingIntegrations();
              } else {
                this.$emit("selectedIntegrations", this.integrations[this.integration_index_selected]);
              }
            }
          });
        }
      },
      ba_id(val) {
        if (val !== undefined && this.service_path && this.user_id) {
          this.getIntegrations(this.service_path, this.user_id, val).then(() => {
            if (this.integration_index_selected === -1){
              this.$emit("selectedIntegrations", {id: null});
            } else {    
              if (this.integrations == undefined || this.integrations[this.integration_index_selected] == undefined){    
                console.log('this.integrations[this.integration_index_selected]', this.integrations[this.integration_index_selected]);          
                this.zeroingIntegrations();
              } else {
                this.$emit("selectedIntegrations", this.integrations[this.integration_index_selected]);
              }
            }
          });
        } else {
          this.zeroingIntegrations();
        }
      },
    },
    mounted() {
      if(this.service_path){
        this.getIntegrations(this.service_path, this.user_id, this.ba_id).then(() => {
          this.$emit("getIntegrations", this.integrations[this.integration_index_selected]);
        });
      }
    },
    methods: {
      getIntegrations(service_path, user_id, ba_id) {
        let params = {
          'user_id': user_id,
          'ba_id': ba_id,
        };
        return  apiService 
          .getBaIntegrations(service_path, params)
          .then((response) => {
            console.log("getIntegrations", response);
            this.integrations = response.data.service_response.data;
            return response;
          });
      },
      zeroingIntegrations(){
        this.integration_index_selected = -1;
        this.integrations = [];
        this.$emit("selectedIntegrations", {id: null});
      }
    }
  }
</script>

<style scoped>

</style>