<template>
  <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
    <div
      class="card-header border-0"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <div class="row align-items-center">
        <div class="col">
          <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
            {{ title }}
          </h3>
        </div>
        <div class="col text-right">
          <base-button
            type="primary"
            size="sm"
            @click="updateAdAccounts()"
          >
            Update
          </base-button>
        </div>
      </div>
    </div>


    <div v-if="loading" class="loading">
      <div class="cssload-container">
        <div class="cssload-whirlpool"></div>
      </div>
    </div>

    <div class="table-responsive"
         v-if="!loading"
    >
      <base-table
        class="table align-items-center table-flush table-integration-settings"
        :class="type === 'dark' ? 'table-dark' : ''"
        :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
        tbody-classes="list"
        :data="ad_accounts"
        v-if="ad_accounts.length > 0"
      >
        <template v-slot:columns>
          <th>Account id</th>
          <th>Balance</th>
          <th>Currency</th>
          <th>Name</th>
          <th>Status</th>
          <th v-if="is_admin">Date Sync</th>
          <th v-if="is_admin">Status Sync</th>
          <th v-if="is_admin"></th>
          <th></th>
        </template>

        <template v-slot:default="row">
          <th scope="row">
            <div class="media align-items-center">
              <!--              <a href="#" class="avatar rounded-circle mr-3">-->
              <!--                <img alt="Image placeholder" :src="row.item.img" />-->
              <!--              </a>-->
              <div class="media-body">
                <span class="name mb-0 text-sm">{{
                  row.item.account_id
                }}</span>
              </div>
            </div>
          </th>
          <td class="balance">
            {{ row.item.balance }}
          </td>
          <td class="currency">
            {{ row.item.currency }}
          </td>
          <td class="name">
            {{ row.item.name }}
          </td>
          <td class="is_active"
              :class="{ 'text-success': row.item.is_active, 'text-danger': !row.item.is_active }"
          >
            {{ row.item.is_active ? "Active" : "Not active" }}

          </td>
          <td v-if="is_admin" class="start sync">
            <span v-if="row.item.date_finish_sync !== undefined">{{ row.item.date_finish_sync }}</span>
          </td>
          <td v-if="is_admin" class="status sync">
            <span 
              v-if="row.item.status_last_sync !== undefined"
              :class="{
                'text-red': row.item.status_last_sync == 'Error',
                'text-green': row.item.status_last_sync == 'Finish',
              }"
            >
              {{ row.item.status_last_sync }}
            </span>
          </td>
          <td class="settings text-right table-integrations_button"
            v-if="is_admin"
          >
            <base-button
              class="button-settings"
              type="primary"
              size="sm"
              @click="openPopupStartSync(row.item)"
            >
              <i
                class="ni ni-cloud-download-95 icon_button"
                title="Start sync"
              ></i>
            </base-button>
          </td>
          <td class="settings" style="max-width: 90px">
            <base-switch :value="returnAccountStatus(row.item.is_active)"
                         class="ml-1"
                         @input="changeAccountStatus($event, row.item)"
            ></base-switch>
<!--            <router-link :to="{ name: 'integration settings', params: { service_path: row.item.service.path, id_integrations: row.item.id }}">-->
<!--              <base-button type="warning" size="sm"> Настроить </base-button>-->
<!--            </router-link>-->
          </td>
        </template>
      </base-table>
      <p class="text-center" v-else>No advertising rooms</p>
    </div>



    <div
      class="card-footer d-flex justify-content-end"
      :class="type === 'dark' ? 'bg-transparent' : ''"
    >
      <!--      <base-pagination total="30"></base-pagination>-->
    </div>
  </div>

  <modal v-model:show="modals.start_sync"
    modalClasses="modal-start-sync"
  >
    <template v-slot:header>
      <h3 class="modal-title" id="modal-title-default">Synchronization</h3>
    </template>

    <div class="mb-4">
      <p class="mb-2">Date start:</p>
      <datepicker v-model="sync_data.date_start" inputFormat="dd-MM-yyyy" />
    </div>
    <div class="mb-4">
      <p class="mb-2">Date end:</p>
      <datepicker v-model="sync_data.date_end" inputFormat="dd-MM-yyyy" />
    </div>

    <div class="input_checkbox ">
      <input type="checkbox" v-model="sync_data.full" />
      <p>Full</p>
    </div>

    <template v-slot:footer>
      <base-button
        type="primary"
        class="ml-auto"
        @click="startSync()"
        >Start sync
      </base-button>
    </template>
  </modal>

  <modal v-model:show="params_modal_message.show"
    modalClasses="modal_message"
  >
    <template v-slot:header>
      <!-- <h3 class="modal-title" id="modal-title-default"></h3> -->
    </template>

    <p
        class="modal_message_text"
        :class="[params_modal_message.status]"
    ><strong>{{params_modal_message.message}}</strong></p>


  </modal>
</template>
<script>
import { mapGetters } from "vuex";
import apiService from "@/services/api";
import apiServiceAdmin from "@/services/admin";
import Datepicker from "vue3-datepicker";

export default {
  name: "projects-table",
  components: {
    Datepicker,
  },
  props: {
    type: {
      type: String,
    },
    title: String,
    is_admin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ad_accounts: [],
      loading: false,
      status: true,
      modals: {
        start_sync: false,
      },
      sync_data: {
        ad_account: {},
        date_start: new Date(),
        date_end: new Date(),
        full: false
      },
      params_modal_message: {
        show: false,
        status: '',
        message: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  created() {
    this.getAdAccounts();
    console.log("user", this.user);
  },
  methods: {
    getAdAccounts() {
      this.loading = true;
      apiService
        .getAdAccounts(this.$route.params.service_path, this.$route.params.id_integrations)
        .then((response) => {
          this.loading = false;
          console.log(response);
          if (response.data.service_response.data.ad_accounts !== undefined){
            this.ad_accounts = response.data.service_response.data.ad_accounts;
          } else {
            this.ad_accounts = [];
          }
          console.log(this.ad_accounts);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    updateAdAccounts() {
      this.loading = true;
      apiService
        .updateAdAccounts(this.$route.params.service_path, this.$route.params.id_integrations)
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.ad_accounts = response.data.service_response.data.ad_accounts;
          console.log(this.ad_accounts);
        })
        .catch(() => {
          this.loading = false;
        });
    },
    returnAccountStatus(account_status){
      return (account_status == 1) ? true : false
    },
    changeAccountStatus($event, account){
      console.log('changeAccountStatus', account);
      apiService
        .updateStatusAdAccounts({
          'user_id': this.user.id,
          'service_path': this.$route.params.service_path,
          'ad_account_id': account.id,
          'status': $event,
        })
        .then((response) => {
          console.log(response);
          if (response.data.service_response.success == true){
            account.is_active = Boolean($event);
          }
        });
    },
    openPopupStartSync(item) {
      this.modals.start_sync = true;
      var date = new Date();
      date.setDate(date.getDate() - 7);

      this.sync_data.date_start = date;
      this.sync_data.date_end = new Date();
      this.sync_data.ad_account = item;
      console.log('sync_data', this.sync_data);
    },
    startSync(){
      console.log('sync_data', this.sync_data);

      let data = {
        date_from: this.sync_data.date_start,
        date_to: this.sync_data.date_end,
        full: this.sync_data.full,
        ba_id: this.$route.params.id_ba,
        fb_id: this.sync_data.ad_account.fb_id,
        account_id: this.sync_data.ad_account.account_id,
      };
      let service_path = this.$route.params.service_path;
      console.log('data', data);
      apiServiceAdmin.startSyncAccount(service_path, data).then((response) => {
        console.log('response', response);
        this.modals.start_sync = false;
        this.params_modal_message = {
          show: true,
          status: 'success',
          message: response.data.service_response.data.status,
        };
      })
      .catch(() => {
        this.modals.start_sync = false;
        this.params_modal_message = {
            show: true,
            status: 'error',
            message: 'Error start sync!',
        };
      });
    }
  },
};
</script>
<style></style>
