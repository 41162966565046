<template>
  <div class="profile_page">
    <base-header
        class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
    >
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <h1 class="display-2 text-white">Hello, {{user.name}}</h1>
            <p class="text-white mt-0 mb-3">
              This is your profile page. Here you can edit contact information.
            </p>
<!--            <a href="#!" class="btn btn-info" @click="goto('profile')">Edit profile</a>-->
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7" ref="profile">
      <div class="row">

        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <template v-slot:header>
              <div class="bg-white border-0">
                <div class="row align-items-center">
                  <div class="col-8 title_wrap">
                    <h3 class="mb-0">My account</h3>
                    <div v-show="loading_profile_info" class="loading_row ml-2">
                      <img v-bind:src="'/img/Reload-1s-200px1.svg'" alt="">
                    </div>
                  </div>
                  <!--                  <div class="col-4 text-right">-->
                  <!--                    <a href="#!" class="btn btn-sm btn-primary">Settings</a>-->
                  <!--                  </div>-->
                </div>
              </div>
            </template>

            <form class="form-profile">
              <div class="overlay" v-show="loading_profile_info" ></div>
              <h6 class="heading-small text-muted mb-4">User information</h6>
              
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                        alternative=""
                        label="Project domain"
                        placeholder="Project domain"
                        input-classes="form-control-alternative"
                        v-model="model.project_path"
                        v-on:keypress="onlyNumberTextDefis"
                        :valid="validate.project_path.isValid"
                        :error="validate.project_path.error"
                        :disabled="is_project_path_disabled"
                        v-on:blur="checkProjectIdDublicate($event)"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                        alternative=""
                        label="Email"
                        placeholder="jesse@example.com"
                        input-classes="form-control-alternative"
                        v-model="model.email"
                        :valid="validate.email.isValid"
                        :error="validate.email.error"
                    />
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <base-input
                        alternative=""
                        label="First name"
                        placeholder="First name"
                        input-classes="form-control-alternative"
                        v-model="model.first_name"
                        :valid="validate.first_name.isValid"
                        :error="validate.first_name.error"
                    />
                  </div>
                  <div class="col-lg-6">
                    <base-input
                        alternative=""
                        label="Last name"
                        placeholder="Last name"
                        input-classes="form-control-alternative"
                        v-model="model.last_name"
                    />
                  </div>
                </div>
              </div>
              <hr class="my-4"/>
              <!-- Address -->
              <h6 class="heading-small text-muted mb-4">Contact information</h6>
              <div class="pl-lg-4">
                <div class="row">
                  <div class="col-lg-4">
                    <base-input
                        alternative=""
                        label="Phone"
                        placeholder="+380671234567"
                        input-classes="form-control-alternative"
                        v-model="model.phone"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                        alternative=""
                        label="Telegram"
                        placeholder="@username"
                        input-classes="form-control-alternative"
                        v-model="model.telegram"
                    />
                  </div>
                  <div class="col-lg-4">
                    <base-input
                        alternative=""
                        label="Facebook"
                        placeholder="https://www.facebook.com/your.username"
                        input-classes="form-control-alternative"
                        v-model="model.facebook"
                    />
                  </div>
                </div>
              </div>
              <div class="text-center">
                <div class="button_wrap">
                  <base-button
                      type="primary"
                      class="my-4"
                      @click.prevent="handleSubmit"
                      :disabled="is_submit"
                  >
                    Save
                  </base-button>
                  <div v-show="is_submit" class="loading_row">
                    <img v-bind:src="'/img/Reload-1s-200px1.svg'" alt="">
                  </div>
                </div>
              </div>
              <div class="text-danger font-italic mt-2 text-center">
                <small>{{ formSubmitError }}</small>
              </div>
            </form>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {mapGetters} from "vuex";
  import apiService from "@/services/api";
  import lStorage from "@/services/localStorage";
  import store from "@/store";

  export default {
    name: "user-profile",
    data() {
      return {
        model: {
          project_path: "",
          email: "",
          first_name: "",
          last_name: "",
          phone: "",
          telegram: "",
          facebook: "",
        },
        business_accounts: {},
        newBusinessAccount: {
          name: '',
          path: ''
        },
        validate: {
          project_path: {
            isValid: null,
            error: "",
            typeValid: [
              {
                type: "required",
                errorMess: "The field must not be empty",
              },
              {
                type: "dublicate",
                errorMess: "Such project id already exists",
              },
            ],
          },
          first_name: {
            isValid: null,
            error: "",
            typeValid: [
              {
                type: "required",
                errorMess: "The field must not be empty",
              },
            ],
          },
          email: {
            isValid: null,
            error: "",
            typeValid: [
              {
                type: "required",
                errorMess: "The field must not be empty",
              },
              {
                type: "email",
                errorMess: "Email not valid",
              },
            ],
          },
        },
        formIsValid: true,
        formSubmitError: "",
        is_project_path_disabled: false,
        is_submit: false,
        loading_profile_info: false,
        project_path_is_dublicate: false
      };
    },
    computed: {
      ...mapGetters({
        user: "user/getUser",
      }),
    },
    watch: {
      "model.first_name"() {
        this.resetErrors("first_name");
      },
      "model.email"() {
        this.resetErrors("email");
      },
      "model.project_path"() {
        this.resetErrors("project_path");
        this.project_path_is_dublicate = true;
      },
    },
    methods: {
      resetErrors(type) {
        this.validate[type].isValid = null;
        this.validate[type].error = "";
        this.formSubmitError = "";
      },
      handleSubmit() {
        this.validateForm();
        // this.checkProjectIdDublicate();
        if (this.formIsValid) {
          this.is_submit = true;
          apiService
            .updateProfile({
              user_id: this.user.id,
              name: this.model.first_name,
              email: this.model.email,
              project_path: this.model.project_path,
              last_name: this.model.last_name,
              phone: this.model.phone,
              telegram: this.model.telegram,
              facebook: this.model.facebook,
            })
            .then((response) => {
              let data = response.data.data;
              this.is_submit = false;
              console.log('submit!', data);

              if (data.status) {
                let user = {
                  'name': this.model.first_name,
                  'email': this.model.email,
                  'id': this.user.id,
                  'project_path': this.model.project_path,
                  'last_name': this.model.last_name,
                  'phone': this.model.phone,
                  'telegram': this.model.telegram,
                  'facebook': this.model.facebook
                }
                console.log('user for store:', user);
                lStorage.setObject("user", user);
                store.commit("user/addUser", user);
              } else {
                let error_mess = "Error save";
                if (data.message !== ''){
                  error_mess = data.message;
                }
                console.log('response.message', data.message);
                console.log('error_mess', error_mess);
                this.formSubmitError = error_mess;
              }
            })
            .catch((error) => {
              console.error("error reg", error);
              if (error.response) {
                console.log(error.response);
                this.is_submit = false;
                this.formSubmitError =
                  error.response.data.message + " " + error.response.data.data;
              }
            });
        }
      },
      onlyNumberTextDefis(evt) {
        evt = (evt) ? evt : window.event;
        let res = /^[aA-zZ0-9-]+$/g.test(evt.key);
        if (!res) {
          return evt.preventDefault();
        } else {
          return true;
        }
      },
      validateForm() {
        this.formIsValid = true;
        let errorMess = "";
        for (let key in this.validate) {
          this.validate[key]["error"] = "";

          this.validate[key]["typeValid"].forEach((typeValid) => {
            if (typeValid.type === "required") {
              this.validate[key]["isValid"] = this.model[key] !== "";
              errorMess = typeValid.errorMess;
            }
            if (this.validate[key]["isValid"] && typeValid.type === "email") {
              this.validate[key]["isValid"] = this.validateEmail(this.model[key]);
              errorMess = typeValid.errorMess;
            }
            if (this.validate[key]["isValid"] && typeValid.type === "dublicate") {
              this.validate[key]["isValid"] = this.project_path_is_dublicate;
              errorMess = typeValid.errorMess;
            }
          });

          if (!this.validate[key]["isValid"]) {
            this.formIsValid = false;
            this.validate[key]["error"] = errorMess;
          }
        }
        if (this.model.password !== this.model.passwordConfirm) {
          this.validate.passwordConfirm.error = "Password mismatch";
          this.formIsValid = false;
        }
      },
      validateEmail(email) {
        let re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return re.test(String(email).toLowerCase());
      },
      goto(refName) {
        let element = this.$refs[refName];
        let top = element.offsetTop;

        window.scrollTo(0, top);
      },
      getProfileInfo(){
        this.loading_profile_info = true;
        apiService
          .getProfileInfo(this.user.id)
          .then((response) => {
            let data = response.data.data;
            if (response.data.success) {
              console.log('success!', data);
              this.model.email = data.user.email;
              this.model.first_name = data.user.name;
              this.model.project_path = data.user.project_path;
              this.model.last_name = data.user.last_name;
              this.model.phone = data.user.phone;
              this.model.telegram = data.user.telegram;
              this.model.facebook = data.user.facebook;

              if (data.user.project_path !== null && data.user.project_path !== ''){
                this.is_project_path_disabled = true
              }
              this.loading_profile_info = false;
            }
          })
          .catch((error) => {
            console.error("error reg", error);
            if (error.response) {
              console.log(error.response);
              this.formSubmitError =
                error.response.data.message + " " + error.response.data.data;
            }
          });
      },
      checkProjectIdDublicate(){
        let project_path = this.model.project_path;
        if (project_path !== null && project_path !== ''){
          apiService
            .projectIdDublicate(project_path)
            .then((response) => {
              let data = response.data.data;
              console.log('dublicate!', data);
              let id_field = 'project_path';
              let errorMess = '';
              if (data.success) {
                this.validate[id_field].isValid = false;
                this.validate[id_field]["typeValid"].forEach((typeValid) => {
                  if (typeValid.type === "dublicate") {
                    errorMess = typeValid.errorMess;
                  }
                });
                this.validate[id_field].error = errorMess;
                this.project_path_is_dublicate = false;
              } else {
                this.validate[id_field].isValid = true;
                this.validate[id_field].error = errorMess;
                this.project_path_is_dublicate = true;
              }
            })
            .catch((error) => {
              console.error("error reg", error);
              if (error.response) {
                console.log(error.response);
              }
            });
        }
      },
      getBusinessAccounts(user_id) {
        return apiService
          .getBusinessAccountsByUserId(user_id)
          .then((response) => {
            console.log("getBusinessAccountsByUserId", response);
            this.business_accounts = response.data.data;
            return response;
          });
      },
      addBusinessAccount() {
        this.is_submit = true;
        const user = lStorage.getObject("user");
        const user_id = user.id
        const { name, path } = this.newBusinessAccount;

        return apiService
            .addBusinessAccount(user_id, name, path)
            .then(response => {
                this.is_submit = false;
                console.log('Business account added successfully', response);
                location.reload();
            })
            .catch(error => {
                this.is_submit = false;
                console.error('Error adding business account:', error);
            });
      }
    },
    created() {
      console.log("user", this.user);
      this.getProfileInfo();
    },
    mounted() {
      let user = lStorage.getObject("user");
      this.getBusinessAccounts(user.id);
    },
  };
</script>
<style></style>
