<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <integration-settings-table-fb
            title="Facebook Ad Accounts"
            :is_admin="true"
            v-if="service_path === 'fb-sync'"
          ></integration-settings-table-fb>
          <integration-settings-table-pd
            title="Pipedrive Accounts"
            :is_admin="true"
            v-if="service_path === 'pd-sync'"
          ></integration-settings-table-pd>
          <integration-settings-table-ws
            title="Webinar Stars Accounts"
            :is_admin="true"
            v-if="service_path === 'ws-sync'"
          ></integration-settings-table-ws>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import IntegrationSettingsTableFb from "../../IntegrationSettings/TableFb";
import IntegrationSettingsTablePd from "../../IntegrationSettings/TablePd";
import IntegrationSettingsTableWs from "../../IntegrationSettings/TableWs";
export default {
  name: "tables",
  components: {
    IntegrationSettingsTableFb,
    IntegrationSettingsTablePd,
    IntegrationSettingsTableWs
  },
  data() {
    return {
      service_path: "",
    };
  },
  created() {
    this.setIdIntegrations();
  },
  methods: {
    setIdIntegrations() {
      this.service_path = this.$route.params.service_path;
    },
  },
};
</script>
<style></style>
