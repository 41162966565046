<template>
  <base-nav
    class="navbar-top navbar-dark"
    id="navbar-main"
    :show-toggle-button="false"
    expand
  >
    <form
      class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
    >
      <div class="form-group mb-0">
<!--        <base-input-->
<!--          placeholder="Search"-->
<!--          class="input-group-alternative"-->
<!--          alternative=""-->
<!--          addon-right-icon="fas fa-search"-->
<!--        >-->
<!--        </base-input>-->
      </div>
    </form>
    <ul class="navbar-nav align-items-center d-none d-md-flex">
      <li class="nav-item dropdown ">
        <base-dropdown class="nav-link pr-0" style="cursor:pointer">
          <template v-slot:title>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img
                  alt="Image placeholder"
                  :src="userIconUrl"
                />
              </span>
              <div class="media-body ml-2 d-none d-lg-block">
                <span class="mb-0 text-sm font-weight-bold">{{user.name}}</span>
              </div>
            </div>
          </template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">{{user.email}}</h6>
          </div>
          <router-link to="/profile" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>My profile</span>
          </router-link>
          <router-link to="/business_accounts" class="dropdown-item">
            <i class="ni ni-briefcase-24"></i>
            <span>Business Accounts</span>
          </router-link>
<!--          <router-link to="/profile" class="dropdown-item">-->
<!--            <i class="ni ni-settings-gear-65"></i>-->
<!--            <span>Settings</span>-->
<!--          </router-link>-->
<!--          <router-link to="/profile" class="dropdown-item">-->
<!--            <i class="ni ni-calendar-grid-58"></i>-->
<!--            <span>Activity</span>-->
<!--          </router-link>-->
<!--          <router-link to="/profile" class="dropdown-item">-->
<!--            <i class="ni ni-support-16"></i>-->
<!--            <span>Support</span>-->
<!--          </router-link>-->
          <div class="dropdown-divider"></div>
          <div class="dropdown-item"
               @click="userLogout()"
          >
            <i class="ni ni-user-run"></i>
            <span>Log out</span>
          </div>
        </base-dropdown>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import { mapGetters } from "vuex";
import userService from "@/services/user";
export default {
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: "",
      userIconUrl: window.location.origin + "/img/theme/user.png",
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  methods: {
    userLogout(){
      userService.logout();
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
  },
};
</script>
