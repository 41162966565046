<template>
  <select class="custom-select" v-model="service_id_selected">
    <!--            <option :value="0" selected>Select an Service</option>-->
    <option
        v-for="(service, index) in services"
        :value="index"
        v-bind:key="index"
    >
      {{ service.name }}
    </option>
  </select>
</template>

<script>
  import apiService from "../../services/api";

  export default {
    name: "SelectServices",
    props: {
      user_id: {
        type: Number,
        require: true,
      },
    },
    emits: ['selectedService'],
    data() {
      return {
        service_id_selected: 0,
        services: [],
      };
    },
    watch: {
      service_id_selected(val) {
        console.log("service_id_selected val", val);
        this.$emit("selectedService", this.services[val]);
      },
      user_id(val) {
        this.getServices(val);
      },
    },
    mounted() {
      this.getServices(this.user_id, 'export').then(() => {
        this.$emit("selectedService", this.services[this.service_id_selected]);
      });
    },
    methods: {
      getServices(user_id, type) {
        return apiService
          .getServices(user_id, type)
          .then((response) => {
            console.log("getServices", response);
            this.services = response.data.data.services;
            return response;
          });
      },
    }
  }
</script>

<style scoped>

</style>
