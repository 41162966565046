<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body px-lg-5 py-lg-5">
          <div class="text-center text-muted mb-4">
            <small>Enter your email to reset your password</small>
          </div>
          <form role="form" @submit.prevent="requestReset">
            <base-input
              formClasses="input-group-alternative mb-3"
              placeholder="Email"
              addon-left-icon="ni ni-email-83"
              v-model="email"
            ></base-input>
            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                @click.prevent="requestReset"
              >
                Reset Password
              </base-button>
            </div>
            <div class="text-danger font-italic mt-2 text-center">
              <small>{{ formSubmitMessage }}</small>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/api";
export default {
  data() {
    return {
      email: "",
      formSubmitMessage: ""
    };
  },
  methods: {
    requestReset() {
      apiService.resetPasswordRequest({ email: this.email })
        .then(response => {
          this.formSubmitMessage = response.data.message;
        })
        .catch(error => {
          this.formSubmitMessage = "Error: Could not send reset request.";
          console.error(error); // Log the error for debugging purposes
        });
    }
  }
};
</script>
