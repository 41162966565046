/*!

=========================================================
* Vue Argon Dashboard - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vue-argon-dashboard
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/vue-argon-dashboard/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import store from "./store";
import ArgonDashboard from "./plugins/argon-dashboard";
import lStorage from "@/services/localStorage";
import "element-plus/lib/theme-chalk/index.css";

const appInstance = createApp(App);

appInstance.use(router);
appInstance.use(ArgonDashboard);
appInstance.use(VueAxios, axios);
appInstance.use(store);

const user = lStorage.getObject("user");
if (user !== null) {
  store.commit("user/addUser", user);
}

const token = lStorage.get("jwt");
if (token !== null) {
  store.commit("user/addToken", token);
}

appInstance.mount("#app");
