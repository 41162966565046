import { createRouter, createWebHistory } from "vue-router";

import DashboardLayout from "@/layout/DashboardLayout";
import AuthLayout from "@/layout/AuthLayout";

import Dashboard from "../views/Dashboard.vue";
import Icons from "../views/Icons.vue";
import Maps from "../views/Maps.vue";
import Profile from "../views/UserProfile.vue";
import BusinessAccounts from "../views/BusinessAccounts.vue";
import Tables from "../views/Tables.vue";
import Invite from "../views/Invite.vue";

import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import PasswordResetRequest from "../views/PasswordResetRequest.vue";
import PasswordResetConfirm from "../views/PasswordResetConfirm.vue";
import Integrations from "../views/Integrations";
import DataExport from "../views/DataExport";

import AdminUsers from "../views/Admin/Users";
import AdminBusinessAccounts from "../views/Admin/BusinessAccounts/Index";
import AdminBusinessAccountsSettings from "../views/Admin/BusinessAccounts/Settings";
import AdminBusinessAccountsUsers from "../views/Admin/BusinessAccounts/Users";
import AdminBusinessAccountsIntegrations from "../views/Admin/BusinessAccounts/Integrations";
import AdminBusinessAccountsIntegrationSettings from "../views/Admin/BusinessAccounts/IntegrationSettings";

import lStorage from "@/services/localStorage";
import ExportSettings from "../views/ExportSettings";
// import IntegrationSettings from "../views/IntegrationSettings";
import IntegrationStep2 from "../views/IntegrationSteps/Step2";
import IntegrationNewSettings from "../views/IntegrationSteps/Settings";

const routes = [
  {
    path: "/",
    redirect: "login",
    component: AuthLayout,
    meta: {
      guest: true,
    },
    children: [
      {
        path: "/login",
        name: "login",
        components: { default: Login },
        meta: {
          title: "Login",
        },
      },
      {
        path: "/register",
        name: "register",
        components: { default: Register },
        meta: {
          title: "Register",
        },
      },
      {
        path: "/password-reset-request",
        name: "password-reset-request",
        components: { default: PasswordResetRequest },
        meta: {
          title: "Password Reset Request",
          guest: true,
        },
      },
      {
        path: "/password-reset-confirm/",
        name: "password-reset-confirm",
        components: { default: PasswordResetConfirm },
        meta: {
          title: "Password Reset Confirm",
          guest: true,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/dashboard",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
      role: "client",
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        components: { default: Dashboard },
        meta: {
          title: "Dashboard. Last 14 days",
        },
      },
      {
        path: "/integrations",
        name: "integrations",
        components: { default: Integrations },
        meta: {
          title: "Integrations",
        },
      },
      {
        path: "/integrations/:integrations_exports_id/step2",
        name: "integration_step2",
        components: { default: IntegrationStep2 },
        meta: {
          title: "Adding Integration. Step2",
        },
      },
      {
        path: "/integrations/:integrations_exports_id/settings",
        name: "integration_settings",
        components: { default: IntegrationNewSettings },
        meta: {
          title: "Integration Settings",
        },
      },
      {
        path: "/data_export",
        name: "data_export",
        components: { default: DataExport },
        meta: {
          title: "Data Export",
        },
      },
      {
        path: "/data_export/settings/:service_path/:id_export",
        name: "export_settings",
        components: { default: ExportSettings },
        meta: {
          title: "Export Settings",
        },
      },
      {
        path: "/icons",
        name: "icons",
        components: { default: Icons },
        meta: {
          title: "Icons",
        },
      },
      {
        path: "/maps",
        name: "maps",
        components: { default: Maps },
        meta: {
          title: "Maps",
        },
      },
      {
        path: "/profile",
        name: "profile",
        components: { default: Profile },
        meta: {
          title: "Profile",
        },
      },
      {
        path: "/business_accounts",
        name: "business_accounts",
        components: { default: BusinessAccounts },
        meta: {
          title: "Business Accounts",
        },
      },
      {
        path: "/tables",
        name: "tables",
        components: { default: Tables },
        meta: {
          title: "tables",
        },
      },
      {
        path: "/invite",
        name: "invite",
        components: { default: Invite },
        meta: {
          title: "Invite",
          // requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/admin",
    component: DashboardLayout,
    meta: {
      requiresAuth: true,
      role: "admin",
    },
    children: [
      {
        path: "/admin/users",
        name: "admin_users",
        components: { default: AdminUsers },
        meta: {
          title: "Users",
        },
      },
      {
        path: "/admin/business-accounts",
        name: "admin_ba",
        components: { default: AdminBusinessAccounts },
        meta: {
          title: "Business Accounts",
        },
      },
      {
        path: "/admin/business-accounts/:id_ba/settings",
        name: "admin_ba_settings",
        components: { default: AdminBusinessAccountsSettings },
        meta: {
          title: "Settings",
        },
      },
      {
        path: "/admin/business-accounts/:id_ba/users",
        name: "admin_ba_users",
        components: { default: AdminBusinessAccountsUsers },
        meta: {
          title: "Users",
        },
      },
      {
        path: "/admin/business-accounts/:id_ba/integrations",
        name: "admin_ba_integrations",
        components: { default: AdminBusinessAccountsIntegrations },
        meta: {
          title: "Integrations",
        },
      },
      {
        path: "/admin/business-accounts/:id_ba/integrations/:service_path/:id_integrations",
        name: "admin_ba_integrations_settings",
        components: { default: AdminBusinessAccountsIntegrationSettings },
        meta: {
          title: "Integrations Settings",
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  linkActiveClass: "active",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let user = lStorage.getObject("user");
    console.log("user", user);
    console.log("this", to);
    if (lStorage.get("jwt") == null) {
      next({
        name: "login",
        params: { nextUrl: to.fullPath },
      });
    } else if (to.matched.some((record) => record.meta.role === "client")) {
      console.log("is client");
      next();
    } else if (to.matched.some((record) => record.meta.role === "admin")) {
      if (user == null || user.role !== "admin") {
        next({
          name: "dashboard",
          params: { nextUrl: to.fullPath },
        });
      } else {
        console.log("is admin");
        next();
      }
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (lStorage.get("jwt") == null) {
      next();
    } else {
      next({ name: "dashboard" });
    }
  } else if (to.matched.some((record) => record.meta.requiresAuthPath)) {
    if (lStorage.get("jwt") == null) {
      next({
        name: "login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      let user = lStorage.getObject("user");
      console.log("user", user);
      if (
        user.project_path == undefined ||
        user.project_path == null ||
        user.project_path == ""
      ) {
        next();
      } else {
        next({ name: "dashboard" });
      }
    }
  }
});

export default router;
