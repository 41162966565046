const user = {
  namespaced: true,
  state() {
    return {
      user: {
        id: "",
        email: "",
        name: "",
        role: "",
        businessAccounts: [],
        currentBusinessAccount: {},
      },
      token: "",
    };
  },
  mutations: {
    addUser: (state, user) => {
      state.user.email = user.email !== undefined ? user.email : "";
      state.user.name = user.name !== undefined ? user.name : "";
      state.user.id = user.id !== undefined ? user.id : "";
      state.user.role = user.role !== undefined ? user.role : "";
    },
    addToken: (state, token) => {
      state.token = token !== undefined ? token : "";
    },
    setBusinessAccounts(state, businessAccounts) {
      state.user.businessAccounts = businessAccounts;
    },
    setCurrentBusinessAccount: (state, businessAccount) => {
      state.user.currentBusinessAccount = businessAccount;
    },
  },
  getters: {
    getUser: (state) => {
      return state.user;
    },
    getToken: (state) => {
      return state.token;
    },
    getCurrentBusinessAccount: (state) => {
      return state.user.currentBusinessAccount;
    },
    getVuexBusinessAccounts: (state) => {
      return state.user.businessAccounts;
    },
  },
};

export default user;
