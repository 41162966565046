<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">

          <business-account-menu></business-account-menu>

          <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
            <div
                class="card-header border-0"
                :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    {{ title }}
                  </h3>
                </div>
                <div class="col text-right">
<!--                  <base-button-->
<!--                      type="primary"-->
<!--                      size="sm"-->
<!--                      @click="modals.addIntegration = true"-->
<!--                  >-->
<!--                    Add integration user-->
<!--                  </base-button>-->
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <router-link
                :to="{
                  name: 'admin_ba_integrations',
                  params: {
                    id_ba: business_account_id,
                  },
                }"
                @click="linkClick"
                class="nav-link"
              >
                <i class="ni ni-spaceship mr-2"></i>
                <span class="nav-link-text">Integrations</span>
              </router-link>
            </div>

            <div
                class="card-footer d-flex justify-content-end"
            >
<!--            <base-pagination-->
<!--                :pageCount="pagination.last_page"-->
<!--                :value="pagination.current_page"-->
<!--                @input="changePage"-->
<!--                v-if="users.length > 0"-->
<!--            ></base-pagination>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import {mapGetters} from "vuex";
import adminService from "@/services/admin";
import BusinessAccountMenu from "@/components/Admin/BusinessAccountMenu.vue"

export default {
  name: "users",
  components: {
    BusinessAccountMenu,
  },
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      title: 'Settings',
      users: [],
      user_settings: {},
      modals: {
        open_settings: false,
      },
      pagination: {
        last_page: 1,
        current_page: 1,
      },
      business_account_id: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  created() {
    console.log("user", this.user);
    console.log('route',this.$route.query);
    if (this.$route.query.page !== undefined){
      this.pagination.current_page = Number(this.$route.query.page);
    }
    this.getUsers(this.pagination.current_page);
    this.setBusinessAccountId();
  },
  methods: {
    setBusinessAccountId() {
      this.business_account_id = this.$route.params.id_ba;
      console.log('this.business_account_id',this.business_account_id);
      console.log('this.$route',this.$route);
    },
    getUsers(page) {
      adminService
        .getUsers(page)
        .then((response) => {
          console.log(response);
          this.users = response.data.data.users.data;
          this.pagination.last_page = Number(response.data.data.users.last_page);
        });
    },
    open_settings_modal(user){
      console.log('open_settings_modal', user);
      this.user_settings = JSON.parse(JSON.stringify(user));
      this.modals.open_settings = true;
    },
    changePage(page){
      this.pagination.current_page = page;
      this.getUsers(page);
      this.$router.push({ name: 'admin_users', query: {page: page} });
    }
  },
};
</script>
<style></style>
