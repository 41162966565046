<template>
  <card header-classes="bg-transparent">
    <template v-slot:header>
      <div class="row align-items-center">
        <div class="col">
          <!--                  <h6 class="text-light text-uppercase ls-1 mb-1">Overview</h6>-->
          <h5 class="h3 mb-0">{{ data.title }}</h5>
        </div>
      </div>
    </template>
    <div class="chart-area">
      <canvas :height="350" :id="canvasChartId"></canvas>
    </div>
  </card>
</template>

<script>
import { Chart } from 'chart.js';
let chart;
export default {
  name: "AppChart",
  props: {
    data: {
      type: Object,
      default: () => ({
        labels: [],
        data: [],
        title: "",
        borderColor: "#f53959",
      }),
      description: "chart data",
    },
  },
  data() {
    return {
      canvasChartId: "canvasChartId",// todo we need different chart id because now this component dont be more 1 into page
    };
  },
  watch: {
    data(val) {
      this.initBigChart(this.canvasChartId, val.labels, val.data, val.title, val.borderColor)
    },
  },
  mounted() {
    this.initBigChart(this.canvasChartId, this.data.labels, this.data.data, this.data.title, this.data.borderColor)
  },
  beforeUnmount() {
    chart.destroy();
  },
  methods: {
    initBigChart(salesChartID, labels, data, title, borderColor = "#5e72e4") {
      if (chart) {
        chart.destroy();
      }
      chart = new Chart(
        document.getElementById(salesChartID).getContext("2d"),
        {
          type: "line",
          data: {
            labels: labels,
            datasets: [
              {
                label: title,
                tension: 0.4,
                borderWidth: 4,
                borderColor: borderColor,
                pointRadius: 0,
                backgroundColor: "transparent",
                data: data,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: false,
            },
            tooltips: {
              enabled: true,
              mode: "index",
              intersect: false,
            },
            scales: {
              yAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 0,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 1.6,
                  gridLines: {
                    drawBorder: false,
                    color: "rgba(29,140,248,0.0)",
                    zeroLineColor: "transparent",
                  },
                  ticks: {
                    padding: 10,
                    fontColor: "#8898aa",
                    fontSize: 13,
                    fontFamily: "Open Sans",
                  },
                },
              ],
            },
            layout: {
              padding: 0,
            },
          },
        }
      );
    },
  }
};
</script>

<style scoped></style>
