<template>
  <div class="profile_page">
    <base-header class="header pb-8 pt-5 pt-lg-8 d-flex align-items-center">
      <!-- Mask -->
      <span class="mask bg-gradient-success opacity-8"></span>
      <!-- Header container -->
      <div class="container-fluid d-flex align-items-center">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <h1 class="display-2 text-white">Hello, {{ user.name }}</h1>
            <p class="text-white mt-0 mb-3">
              This page contains everything related to your business accounts.
            </p>
            <!--            <a href="#!" class="btn btn-info" @click="goto('profile')">Edit profile</a>-->
          </div>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7" ref="profile">
      <div class="row">
        <div class="col-xl-12 order-xl-1 edit-container" v-if="showEditPopup">
          <form @submit.prevent="updateAccount" class="edit-account-form">
            <div class="form-group-edit">
              <input v-model="currentAccount.name" placeholder="Name" class="form-control-edit" />
            </div>
            <div class="form-group-edit">
              <input v-model="currentAccount.path" placeholder="Path" class="form-control-edit" />
            </div>
            <div class="form-action">
              <base-button type="primary" class="edit-btn" @click.prevent="editBusinessAccount(this.currentAccount)"
                :disabled="is_submit">
                Edit
              </base-button>
            </div>
          </form>
        </div>


        <div class="col-xl-12 order-xl-1">
          <card shadow type="secondary">
            <!-- Business Accounts -->
            <h6 class="heading-small text-muted mb-4">Business Accounts</h6>
            <div class="pl-lg-4 overscroll">
              <div v-if="business_accounts.length > 0">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Path</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(account, index) in business_accounts" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>{{ account.name }}</td>
                      <td>{{ account.path }}</td>
                      <td>
      <i class="fas fa-edit cursor-pointer" @click="showEditForm(account)"></i>
    </td>
    <td>
      <i class="fas fa-trash cursor-pointer" @click="deleteBusinessAccount(account)"></i>
    </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else>
                <p>No business accounts found.</p>
              </div>
            </div>
            <hr class="my-4" />
            <div class="add-business-account-form">
              <h6 class="heading-small text-muted mb-4">Add business account</h6>
              <form class="add-container" @submit.prevent="addBusinessAccount">
                <div>
                  <base-input id="businessName" type="text" label="Name" placeholder="name"
                    v-model="newBusinessAccount.name" />
                </div>
                <div>
                  <base-input id="businessPath" type="text" label="Path" placeholder="path"
                    v-model="newBusinessAccount.path" />
                </div>
                <div class="flex-align-center mt-7px">
                  <div class="button_wrap">
                    <base-button type="primary" class="my-4" @click.prevent="addBusinessAccount()" :disabled="is_submit">
                      Add
                    </base-button>
                    <div v-show="is_submit" class="loading_row">
                      <img v-bind:src="'/img/Reload-1s-200px1.svg'" alt="">
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </card>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import apiService from "@/services/api";

export default {
  name: "user-profile",
  data() {
    return {
      business_accounts: {},
      newBusinessAccount: {
        name: '',
        path: ''
      },
      formIsValid: true,
      formSubmitError: "",
      is_project_path_disabled: false,
      is_submit: false,
      loading_profile_info: false,
      project_path_is_dublicate: false,
      currentAccount: null,
      showEditPopup: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  methods: {
    resetErrors(type) {
      this.validate[ type ].isValid = null;
      this.validate[ type ].error = "";
      this.formSubmitError = "";
    },
    goto(refName) {
      let element = this.$refs[ refName ];
      let top = element.offsetTop;

      window.scrollTo(0, top);
    },
    getBusinessAccounts(user_id) {
      return apiService
        .getBusinessAccountsByUserId(user_id)
        .then((response) => {
          console.log("getBusinessAccountsByUserId", response);
          this.business_accounts = response.data.data;
          return response;
        });
    },
    addBusinessAccount() {
      this.is_submit = true;
      const { name, path } = this.newBusinessAccount;

      return apiService
        .addBusinessAccount(this.user.id, name, path)
        .then(response => {
          this.is_submit = false;
          console.log('Business account added successfully', response);
          location.reload();
        })
        .catch(error => {
          this.is_submit = false;
          console.error('Error adding business account:', error);
        });
    },
    showEditForm(account) {
      this.currentAccount = account;
      this.showEditPopup = !this.showEditPopup ? true : false;
    },
    editBusinessAccount(account) {
      this.showEditPopup = true;
      return apiService
        .editBusinessAccount(this.user.id, account.name, account.path)
        .then(response => {
          this.is_submit = false;
          console.log('Business account edited successfully', response);
          //location.reload();
          this.showEditPopup = false;
        })
        .catch(error => {
          this.is_submit = false;
          console.error('Error editing business account:', error);
        });
    },
    deleteBusinessAccount(account) {
      return apiService
        .deleteBusinessAccount(this.user.id, account.name, account.path)
        .then(response => {
          this.is_submit = false;
          console.log('Business account added successfully', response);
          //location.reload();
        })
        .catch(error => {
          this.is_submit = false;
          console.error('Error adding business account:', error);
        });
    },
  },
  mounted() {
    this.getBusinessAccounts(this.user.id);
  },
};
</script>
<style>
.add-container {
  display: flex;
  gap: 2em;
}
.flex-align-center {
  display: flex;
  align-items: center;
}
.edit-container {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}

.edit-account-form {
  display: flex;
  align-items: center;
  gap: 20px;
}

.form-group-edit {
  margin-bottom: 0 !important;
}

.form-control-edit {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.edit-btn {
  padding: 10px 20px;
}

.overscroll {
  overflow: scroll;
}

.mt-7px {
  margin-top: 7px;
}

@media (max-width: 768px) {
  .edit-account-form {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
