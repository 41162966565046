<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <export-settings-table-fb
            title="Facebook Ad Accounts"
            v-if="service_path === 'fb-sync'"
          ></export-settings-table-fb>
          <export-settings-table-pd
            title="Pipedrive Accounts"
            v-if="service_path === 'pd-sync'"
          ></export-settings-table-pd>
          <export-settings-table-ws
            title="Webinar Stars Accounts"
            v-if="service_path === 'ws-sync'"
          ></export-settings-table-ws>
          <export-settings-table-ga
            title="Google Ads Accounts"
            v-if="service_path === 'google-ads'"
          ></export-settings-table-ga>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ExportSettingsTableFb from "./ExportSettings/TableFb";
import ExportSettingsTablePd from "./ExportSettings/TablePd";
import ExportSettingsTableWs from "./ExportSettings/TableWs";
import ExportSettingsTableGa from "./ExportSettings/TableGa";
export default {
  name: "tables",
  components: {
    ExportSettingsTableFb,
    ExportSettingsTablePd,
    ExportSettingsTableWs,
    ExportSettingsTableGa
  },
  data() {
    return {
      service_path: "",
    };
  },
  created() {
    this.setIdIntegrations();
  },
  methods: {
    setIdIntegrations() {
      this.service_path = this.$route.params.service_path;
    },
  },
};
</script>
<style></style>
