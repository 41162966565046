<template>
  <select class="custom-select" v-model="account_index_selected">
    <option :value="-1" selected>Select an Ad account</option>
    <option
        v-for="(account, index) in ad_accounts"
        :value="index"
        v-bind:key="index"
    >
      {{ account.name }}
    </option>
  </select>
</template>

<script>
  import apiService from "../../services/api";

  export default {
    name: "SelectAdAccount",
    props: {
      user_id: {
        type: Number,
        require: true,
      },
      service_path: {
        type: String,
        require: true,
      },
      ba_id: {
        type: Number,
        require: true,
      },
      integration_id: {
        type: Number,
        require: true,
      },
    },
    emits: ['selectedAccount'],
    data() {
      return {
        account_index_selected: -1,
        ad_accounts: [],
      };
    },
    watch: {
      account_index_selected(val) {
        console.log("account_index_selected val", val);
        if (val === -1){
          this.$emit("selectedAccount", {account_id: null});
        } else {
          this.$emit("selectedAccount", this.ad_accounts[val]);
        }
      },
      // user_id(val) {
      //   if(this.service_path) {
      //     this.getAdAccounts(this.service_path, this.integration_id);
      //   }
      // },
      service_path(val) {
        if(val) {
          this.getAdAccounts(val, this.integration_id).then(() => {
            if (this.account_index_selected === -1){
              this.$emit("selectedAccount", {account_id: null});
            } else {
              if (this.ad_accounts == undefined || this.ad_accounts[this.account_index_selected] == undefined){    
                console.log('this.ad_accounts[this.account_index_selected]', this.ad_accounts[this.account_index_selected]);          
                this.zeroingAddAccounts();
              } else {
                this.$emit("selectedAccount", this.ad_accounts[this.account_index_selected]);
              }
            }
          });
        } else {
          this.zeroingAddAccounts();
        }
      },
      ba_id(val) {
        if (val !== undefined && this.service_path && this.integration_id) {
          this.getAdAccounts(this.service_path, this.integration_id).then(() => {
            if (this.account_index_selected === -1){
              this.$emit("selectedAccount", {account_id: null});
            } else {
              if (this.ad_accounts == undefined || this.ad_accounts[this.account_index_selected] == undefined){    
                this.zeroingAddAccounts();
              } else {
                this.$emit("selectedAccount", this.ad_accounts[this.account_index_selected]);
              }
            }
          });
        } else {
          this.zeroingAddAccounts();
        }
      },
      integration_id(val) {
        if (val !== undefined && this.service_path) {
          this.getAdAccounts(this.service_path, val).then(() => {
            if (this.account_index_selected === -1){
              this.$emit("selectedAccount", {account_id: null});
            } else {              
              if (this.ad_accounts == undefined || this.ad_accounts[this.account_index_selected] == undefined){    
                this.zeroingAddAccounts();
              } else {
                this.$emit("selectedAccount", this.ad_accounts[this.account_index_selected]);
              }
              
            }
            
          });
        } else {
          this.zeroingAddAccounts();
        }
      },
    },
    mounted() {
      if(this.service_path && this.integration_id){
        this.getAdAccounts(this.service_path, this.integration_id).then(() => {
          this.$emit("selectedAccount", this.ad_accounts[this.account_index_selected]);
        });
      }
    },
    methods: {
      getAdAccounts(service_path, integration_id) {
        return  apiService
          .getAdAccounts(service_path, integration_id)
          .then((response) => {
            console.log("getAdAccounts", response);
            this.ad_accounts = response.data.service_response.data.ad_accounts;
            return response;
          });
      },
      zeroingAddAccounts(){
        this.ad_accounts = [];
        this.account_index_selected = -1;
        this.$emit("selectedAccount", {account_id: null});
      }
    }
  }
</script>

<style scoped>

</style>