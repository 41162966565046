<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
            <div
                class="card-header border-0"
                :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    {{ title }}
                  </h3>
                </div>
                <div class="col text-right">
<!--                  <base-button-->
<!--                      type="primary"-->
<!--                      size="sm"-->
<!--                      @click="modals.addIntegration = true"-->
<!--                  >-->
<!--                    Add integration user-->
<!--                  </base-button>-->
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <base-table
                  class="table align-items-center table-flush table-users"
                  :class="type === 'dark' ? 'table-dark' : ''"
                  :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
                  tbody-classes="list"
                  :data="business_accounts"
              >
                <template v-slot:columns>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Path</th>
                  <th></th>
                </template>

                <template v-slot:default="row">
                  <th scope="row">
                    <div class="media align-items-center">
                      <div class="media-body">
                <span class="name mb-0 text-sm">{{
                  row.item.id
                }}</span>
                      </div>
                    </div>
                  </th>
                  <td class="account">
                    {{ row.item.name }}
                  </td>
                  <td class="account">
                    {{ row.item.path }}
                  </td>
                  <td class="settings text-right table-integrations_button">
                    <router-link
                        :to="{
                          name: 'admin_ba_settings',
                          params: {
                            id_ba: row.item.id,
                          },
                        }"
                    >
                      <base-button class="button-settings" type="warning" size="sm"> Settings </base-button>
                    </router-link>
<!--                    <base-button-->
<!--                        class="button-delete"-->
<!--                        type="default"-->
<!--                        size="sm"-->
<!--                    >-->
<!--                      Disable-->
<!--                    </base-button>-->
                  </td>
                </template>
              </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end"
                :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <base-pagination
                  :pageCount="pagination.last_page"
                  :value="pagination.current_page"
                  @input="changePage"
                  v-if="business_accounts.length > 0"
              ></base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>

import {mapGetters} from "vuex";
import adminService from "@/services/admin";

export default {
  name: "users",
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      title: 'Business Accounts',
      business_accounts: [],
      pagination: {
        last_page: 1,
        current_page: 1,
      }
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  created() {
    console.log("user", this.user);
    if (this.$route.query.page !== undefined){
      this.pagination.current_page = Number(this.$route.query.page);
    }
    this.getBusinessAccounts(this.pagination.current_page);
  },
  methods: {
    getBusinessAccounts(page) {
      adminService
        .getBusinessAccounts(page)
        .then((response) => {
          console.log(response);
          this.business_accounts = response.data.data.business_accounts.data;
          this.pagination.last_page = Number(response.data.data.business_accounts.last_page);
        });
    },
    changePage(page){
      this.pagination.current_page = page;
      this.getBusinessAccounts(page);
      this.$router.push({ name: 'admin_ba', query: {page: page} });
    }
  },
};
</script>
<style></style>
