import http from "axios";
import axios from "axios";
import store from "../store";
// import userService from "./user";
import router from "@/router";
// import adminService from "./admin";
import lStorage from "@/services/localStorage";

const baseUrl = process.env.VUE_APP_BACKEND_URL;

const ifErrLogout = function (error) {
  console.error(error);
  if (error.response && error.response.status === 401) {
    // userService.logout();
  }
  if (error.response && error.response.status === 403) {
    router.push({ name: "dashboard" });
  }
};

const register = function (data) {
  let url = baseUrl + "/api/register";
  return http
    .post(url, {
      name: data.name,
      email: data.email,
      password: data.password,
      c_password: data.c_password,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const login = function (data) {
  let url = baseUrl + "/api/login";
  return http
    .post(url, {
      email: data.email,
      password: data.password,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const resetPasswordRequest = function (data) {
  let url = baseUrl + "/api/password/reset/request";
  return axios.post(url, data);
};

const resetPasswordConfirm = function (data) {
  let url = baseUrl + "/api/password/reset/confirm";
  return axios.post(url, data);
};

const getServices = function (user_id, type = null) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .get(baseUrl + "/api/services?user_id=" + user_id + "&type=" + type, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getAllServices = function () {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .get(baseUrl + "/api/all-services", {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getAuthData = function (user_id) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .get(baseUrl + "/api/get-auth-data?user_id=" + user_id, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getIntegrationsExports = function (user_id) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .get(baseUrl + "/api/integrations_exports?user_id=" + user_id, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const addExport = function (user_id, service, integrations_exports_id) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  const ba_id = lStorage.getObject("user").currentBusinessAccount.id;

  const url =
    `${baseUrl}/api/${service.service_path}/state?user_id=${user_id}&ba_id=${ba_id}` +
    (integrations_exports_id
      ? `&integrations_exports_id=${integrations_exports_id}`
      : "");

  return http
    .get(url, { headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const deleteExport = function (receiving_service_token) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };

  return http
    .delete(`${baseUrl}/api/delete-auth-data`, {
      headers: headers,
      data: {
        receiving_service_token: receiving_service_token,
      },
    })
    .then((response) => {
      console.log("Delete successful", response);
      return response.data;
    })
    .catch((error) => {
      console.error("Error during delete", error);
      throw error;
    });
};

const deleteExportOAuth = function (service_id, access_token) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };

  return http
    .delete(`${baseUrl}/api/delete-auth-data-oauth`, {
      headers: headers,
      data: {
        service_id: service_id,
        access_token: access_token,
      },
    })
    .then((response) => {
      console.log("Delete successful", response);
      return response.data;
    })
    .catch((error) => {
      console.error("Error during delete", error);
      throw error;
    });
};

const addIntegration = function (user_id, service) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  const ba_id = lStorage.getObject("user").currentBusinessAccount.id;
  console.log(ba_id);
  return http
    .get(
      `${baseUrl}/api/${service.service_path}/state?user_id=${user_id}&ba_id=${ba_id}`,
      { headers }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};
const updateIntegration = function (user_id, service_path) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  let url = baseUrl + "/api/" + service_path + "/users/";
  let data = {
    user_id: user_id,
  };
  return http
    .post(url, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const deleteIntegration = function (params) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  let url = baseUrl + "/api/" + params.service_path + "/users";
  let data = {
    user_id: params.user_id,
    integration_id: params.id_integration,
  };
  return http
    .delete(url, {
      headers: headers,
      data: data,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const updateStatusExport = function (params) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  let url = baseUrl + "/api/change-export-status";
  let data = {
    created_by: params.created_by,
    export_id: params.export_id,
    status: params.status,
  };
  return http
    .patch(url, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const updateStatusIntegration = function (params) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  let url = baseUrl + "/api/" + params.service_path + "/users";
  let data = {
    user_id: params.user_id,
    integration_id: params.integration_id,
    status: params.status,
  };
  return http
    .patch(url, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const addAuthData = function (formdata) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .post(`${baseUrl}/api/add-auth-data`, formdata, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getInsights = function (user_id, service_path, account_id) {
  let token = store.getters["user/getToken"];
  let url_account_id = account_id ? `&account_id=${account_id}` : "";
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .get(
      baseUrl +
        "/api/" +
        service_path +
        "/insights?user_id=" +
        user_id +
        url_account_id,
      {
        headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const updateInsights = function (user_id, service_path, integration_id) {
  let token = store.getters["user/getToken"];
  // eslint-disable-next-line no-unused-vars
  const headers = {
    Authorization: "Bearer " + token,
  };
  // eslint-disable-next-line no-unused-vars
  let url = baseUrl + "/api/" + service_path + "/insights";
  let data = {
    user_id: user_id,
  };
  if (integration_id) {
    data.integration_id = integration_id;
  }
  return http
    .post(url, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getAdAccounts = function (service_path, id_integrations) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .get(
      baseUrl +
        "/api/" +
        service_path +
        "/ad-accounts?integration_id=" +
        id_integrations,
      {
        headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getAdAccountsByUserId = function (service_path, params) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  var queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  return http
    .get(baseUrl + "/api/" + service_path + "/ad-accounts?" + queryString, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getBusinessAccountsByUserId = function (user_id) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .get(baseUrl + "/api/business-accounts?user_id=" + user_id, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const addBusinessAccount = function (user_id, name, path) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .post(
      baseUrl + "/api/add_business_account",
      {
        user_id,
        name,
        path,
      },
      { headers }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const deleteBusinessAccount = function (user_id, name, path) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .post(
      baseUrl + "/api/delete_business_account",
      {
        user_id,
        name,
        path,
      },
      { headers }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const editBusinessAccount = function (user_id, name, path) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .post(
      baseUrl + "/api/edit_business_account",
      {
        user_id,
        name,
        path,
      },
      { headers }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getIntegrations = function (service_path, params) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  var queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  return http
    .get(baseUrl + "/api/" + service_path + "/users?" + queryString, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getBaIntegrations = function (service_path, params) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  var queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
  return http
    .get(baseUrl + "/api/" + service_path + "/ba/users?" + queryString, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const updateAdAccounts = function (service_path, id_integrations) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  let url = baseUrl + "/api/" + service_path + "/ad-accounts";
  let data = {
    integration_id: id_integrations,
  };
  return http
    .post(url, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const updateStatusAdAccounts = function (params) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  let url = baseUrl + "/api/" + params.service_path + "/ad-accounts";
  let data = {
    user_id: params.user_id,
    ad_account_id: params.ad_account_id,
    status: params.status,
  };
  return http
    .patch(url, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getProfileInfo = function (user_id) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .get(baseUrl + "/api/profile?user_id=" + user_id, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const updateProfile = function (data) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  let url = baseUrl + "/api/profile";
  return http
    .post(url, data, {
      headers: headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const projectIdDublicate = function (project_id) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .get(baseUrl + "/api/profile_check_path?project_id=" + project_id, {
      headers,
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getInviteInfo = function (invite_hash) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .post(
      baseUrl + "/api/get-invite-info",
      { invite_hash },
      { headers: headers }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const acceptInvite = function (invite_hash, user_id) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  let url = baseUrl + "/api/invite-accept";
  return http
    .post(
      url,
      { invite_hash: invite_hash, user_id: user_id },
      { headers: headers }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const declineInvite = function (invite_hash) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  let url = baseUrl + "/api/invite-decline";
  return http
    .post(url, { invite_hash }, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getIntegrationInfo = function (user_id, integration_id) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .get(
      baseUrl + "/api/integrations/" + integration_id + "?user_id=" + user_id,
      {
        headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const getIntegrationFbForms = function (user_id, integration_id, page_id) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  return http
    .get(
      baseUrl +
        "/api/get_forms?user_id=" +
        user_id +
        "&integration_id=" +
        integration_id +
        "&page_id=" +
        page_id,
      {
        headers,
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const saveIntegrationSettings = function (data) {
  let token = store.getters["user/getToken"];
  const headers = {
    Authorization: "Bearer " + token,
  };
  let url = baseUrl + "/api/integrations/save";
  return http
    .post(url, data, { headers: headers })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      ifErrLogout(error);
      return Promise.reject(error);
    });
};

const api = {
  updateStatusAdAccounts,
  updateAdAccounts,
  getAdAccounts,
  updateInsights,
  getInsights,
  deleteIntegration,
  updateIntegration,
  addIntegration,
  addExport,
  deleteExport,
  deleteExportOAuth,
  addAuthData,
  updateStatusExport,
  updateStatusIntegration,
  getServices,
  getAllServices,
  getAuthData,
  getIntegrationsExports,
  login,
  register,
  resetPasswordRequest,
  resetPasswordConfirm,
  getAdAccountsByUserId,
  getBusinessAccountsByUserId,
  addBusinessAccount,
  editBusinessAccount,
  deleteBusinessAccount,
  updateProfile,
  getProfileInfo,
  projectIdDublicate,
  getIntegrations,
  getBaIntegrations,
  getInviteInfo,
  acceptInvite,
  declineInvite,
  getIntegrationInfo,
  getIntegrationFbForms,
  saveIntegrationSettings,
};

export default api;
