<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8 dashboard-header">
      <div class="row">
        <div class="dashboard-col-select mb-5 mb-xl-0">
          <SelectServices
              :user_id="user.id"
              @selectedService="selectedService" />
        </div>
        <div class="dashboard-col-select mb-5 mb-xl-0">
          <SelectBA
              :user_id="user.id"
              :service_path="selected_service_path"
              @selectedBA="selectedBA" />
        </div>
        <div class="dashboard-col-select mb-5 mb-xl-0">
          <SelectIntegrations
              :user_id="user.id"
              :service_path="selected_service_path"
              :ba_id="selected_ba_id"
              @selectedIntegrations="selectedIntegrations" />
        </div>
        <div class="dashboard-col-select mb-5 mb-xl-0">
          <SelectAdAccount
              :user_id="user.id"
              :service_path="selected_service_path"
              :ba_id="selected_ba_id"
              :integration_id="selected_integration_id"
              @selectedAccount="selectedAccount" />
        </div>
        <div class="dashboard-col-select mb-5 mb-xl-0 position-relative dashboard_update_wrap">
          <ButtonUpdateInsights
            :service_path="selected_service_path"
            :integration_id="selected_integration_id"
            @update_insights="updateInsights"
          />
        </div>
      </div>
    </base-header>

    <FbInsights
        :service_path="selected_service_path"
        :account_id="selected_account_id"
        :key="FbInsightsKey"
        v-if="selected_service_path == 'fb-sync'"
    />
    <PdInsights
        :service_path="selected_service_path"
        :account_id="selected_account_id"
        :key="FbInsightsKey"
        v-if="selected_service_path == 'pd-sync'"
    />
    <WsInsights
        :service_path="selected_service_path"
        :account_id="selected_account_id"
        :key="FbInsightsKey"
        v-if="selected_service_path == 'ws-sync'"
    />
    <GaInsights
        :service_path="selected_service_path"
        :account_id="selected_account_id"
        :key="FbInsightsKey"
        v-if="selected_service_path == 'google-ads'"
    />
  </div>
</template>
<script>
// Charts
import { Chart, LineController, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
Chart.register(LineController, LineElement, PointElement, LinearScale, Title, CategoryScale);


import { mapGetters } from "vuex";
import SelectServices from "./Dashboard/SelectServices";
import SelectAdAccount from "./Dashboard/SelectAdAccount";
import SelectBA from "./Dashboard/SelectBA";
import SelectIntegrations from "./Dashboard/SelectIntegrations";
import FbInsights from "./Dashboard/services/FbInsights";
import PdInsights from "./Dashboard/services/PdInsights";
import WsInsights from "./Dashboard/services/WsInsights";
import GaInsights from "./Dashboard/services/GaInsights";
import ButtonUpdateInsights from "./Dashboard/ButtonUpdateInsights";

export default {
  components: {
    SelectServices,
    SelectAdAccount,
    SelectBA,
    SelectIntegrations,
    FbInsights,
    PdInsights,
    WsInsights,
    GaInsights,
    ButtonUpdateInsights
  },
  data() {
    return {
      selected_account_id: null,
      selected_ba_id: null,
      selected_integration_id: null,
      selected_service_path: '',
      update_insights: false,
      FbInsightsKey: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  created() {
    console.log("user", this.user);
  },
  methods: {
    selectedAccount(account) {
      console.log('selectedAccount',account, account.account_id);
      this.selected_account_id = account.account_id;
    },
    selectedBA(account) {
      console.log('selectedBA',account, account.id);
      this.selected_ba_id = account.id;
    },
    selectedIntegrations(integration) {
      console.log('selectedIntegrations', integration, integration.id);
      this.selected_integration_id = integration.id;
    },
    selectedService(service) {
      console.log('selectedService',service);
      this.selected_service_path = service.service_path;
    },
    updateInsights(result){
      console.log('Dashboard updateInsights result',result);
      if (result){
        this.FbInsightsKey += 1;
      }
    },
  },
  mounted() {
  },
};
</script>
<style></style>
