<template>
  <div>
    <base-header type="gradient-primary" class="pb-6 pb-8 pt-5 pt-md-8">
      <!-- Invitation Header -->
      <div class="row justify-content-center">
        <div class="col-lg-8 text-center">
          <h1 class="text-white">You've Been Invited!</h1>
          <p class="text-lead text-light">
            You were invited to a business account
          </p>
          <p class="text-lead text-light">
            by {{ this.invite.email }}
          </p>
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--7">
      <div class="row justify-content-center">
        <div class="col-lg-6">
          <div>
            <div class="text-center">
              <button class="btn btn-success" @click="acceptInvite(inviteHash, user_id)">Accept</button>
              <button class="btn btn-danger" @click="declineInvite(inviteHash, user_id)">Decline</button>
            </div>
            <p class="invite-response" v-if="responseMessage">{{ responseMessage }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "@/services/api";
import lStorage from "@/services/localStorage";

export default {
  name: 'Invite',
  data() {
    return {
      inviteHash: null,
      responseMessage: '',
      invite: '',
      user_id: null,
    }
  },
  methods: {
    getInviteInfo(invite_hash) {
      apiService.getInviteInfo(invite_hash)
        .then(response => {
          this.invite = response.data.invite;
          console.log("Invite Info: ", response);
        })
        .catch(error => {
          this.responseMessage = error.response.data.message;
          console.error("Error: ", error.response.data.message);
          this.$router.push('/dashboard');
        });
    },
    acceptInvite(invite_hash, user_id) {
      apiService.acceptInvite(invite_hash, user_id)
        .then(response => {
          this.responseMessage = response.data.message;
          console.log("Invitation Accepted: ", response.data.message);
        })
        .catch(error => {
          this.responseMessage = error.response.data.message;
          console.error("Error: ", error.response.data.message);
        });
    },
    declineInvite(invite_hash) {
      apiService.declineInvite(invite_hash)
        .then(response => {
          this.responseMessage = response.data.message;
          console.log("Invitation Declined: ", response.data.message);
        })
        .catch(error => {
          this.responseMessage = error.response.data.message;
          console.error("Error: ", error.response.data.message);
        });
    },
  },
  created() {
    let user = lStorage.getObject("user");
    this.user_id = user.id
    this.inviteHash = this.$route.query.hash; 
    this.getInviteInfo(this.inviteHash);
  },
};
</script>

<style scoped>
  .invite-response {
    color: gold;
    text-align: center;
    margin-top: 10px;
  }
</style>
