const lStorage = (function () {
  "use strict";

  function eqNull(value) {
    return typeof value === "undefined" || value === null || value === "";
  }

  function StorageService() {
    this.VERSION_STORAGE = "0.0.1";
  }

  StorageService.prototype.set = function (key, data) {
    if (typeof data === "undefined" || data === null) {
      console.error("data can't be undefined or null", `key=${key}`, data);
      return;
    }
    localStorage.setItem(key + this.VERSION_STORAGE, data);
  };
  StorageService.prototype.get = function (key) {
    return localStorage.getItem(key + this.VERSION_STORAGE);
  };
  StorageService.prototype.setObject = function (key, data) {
    if (typeof data === "undefined" || data === null) {
      console.error("data can't be undefined or null", `key=${key}`, data);
      return;
    }
    localStorage.setItem(key + this.VERSION_STORAGE, JSON.stringify(data));
  };
  StorageService.prototype.getObject = function (key) {
    let val = localStorage.getItem(key + this.VERSION_STORAGE);
    if (eqNull(val)) {
      return null;
    }
    return JSON.parse(val);
  };
  StorageService.prototype.remove = function (key) {
    localStorage.removeItem(key + this.VERSION_STORAGE);
  };
  return new StorageService();
})();

export default lStorage;
