<template>
    <div class="container-fluid dashboard-container-insights">
      <div class="row mt-4 mb-2">
        <div class="col-xl-3 col-lg-6">
          <stats-card
              role="button"
              title="Clicks"
              type="gradient-red"
              :sub-title="insights.clicks"
              icon="ni ni-active-40"
              class="mb-4 mb-xl-0"
              @click="selectedChart = 'adsClickChart'"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
              role="button"
              title="Impressions"
              type="gradient-orange"
              :sub-title="insights.impressions"
              icon="ni ni-chart-pie-35"
              class="mb-4 mb-xl-0"
              @click="selectedChart = 'adsImpressionChart'"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
              role="button"
              title="Spend"
              type="gradient-green"
              :sub-title="convertIntToString(insights.spend)"
              icon="ni ni-money-coins"
              class="mb-4 mb-xl-0"
              @click="selectedChart = 'adsSpendChart'"
          >
          </stats-card>
        </div>
        <div class="col-xl-3 col-lg-6">
          <stats-card
              role="button"
              title="Interactions"
              type="gradient-info"
              :sub-title="insights.interactions"
              icon="ni ni-chart-bar-32"
              class="mb-4 mb-xl-0"
              @click="selectedChart = 'adsUniqueClickChart'"
          >
          </stats-card>
        </div>
      </div>
      <!--Charts-->
      <div class="row mt-4">
        <div class="col-xl-12 mb-5 mb-xl-0">
          <AppChart :data="charts[selectedChart]"></AppChart>
        </div>
      </div>
      <!-- End charts-->
    </div>
  </template>
  
  <script>
    import {mapGetters} from "vuex";
    import apiService from "../../../services/api";
    import AppChart from "../AppChart";
  
    export default {
      name: "FbInsights",
      components: {AppChart},
      props: {
        account_id: {
          type: Number,
          default: null,
        },
        service_path: {
          type: String,
          require: true,
        },
      },
      data() {
        return {
          selectedChart: "adsClickChart",
          charts: {
            adsClickChart: {
              labels: [],
              data: [],
              title: "Click",
              borderColor: "#f53959",
            },
            adsImpressionChart: {
              labels: [],
              data: [],
              title: "Impressions",
              borderColor: "#fba240",
            },
            adsSpendChart: {
              labels: [],
              data: [],
              title: "Spend",
              borderColor: "#2dce92",
            },
            adsUniqueClickChart: {
              labels: [],
              data: [],
              title: "Interactions",
              borderColor: "#5e72e4",
            },
          },
          insights: {},
        };
      },
      computed: {
        ...mapGetters({
          user: "user/getUser",
        }),
      },
      created() {
        console.log("GoogleInsights user", this.user);
        console.log("GoogleInsights account_id", this.account_id);
        console.log("GoogleInsights service_path", this.service_path);
      },
      watch: {
        account_id(val) {
          console.log("GoogleInsights watch account_id val", val);
          this.getInsights(
            this.service_path,
            val
          );
        },
        service_path(val) {
          console.log("GoogleInsights watch service_path val", val);
          this.getInsights(
            val,
            this.account_id
          );
        },
      },
      methods: {
        convertIntToString(val) {
          if (val) return val.toFixed(0);
          return 0;
        },
        getInsights(service_path, account_id = null) {
          let user_id = this.user.id;
  
          apiService
            .getInsights(user_id, service_path, account_id)
            .then((response) => {
              console.log('getInsights',response);
              this.updateInsightsData(response.data.service_response.data);
            });
        },
        updateInsightsData(data){
          if (data.length == 0){
            this.insights = {};
            this.charts.adsClickChart.labels = [];
            this.charts.adsClickChart.clicks = [];
  
            this.charts.adsSpendChart.labels = [];
            this.charts.adsSpendChart.clicks = [];
  
            this.charts.adsImpressionChart.labels = [];
            this.charts.adsImpressionChart.clicks = [];
  
            this.charts.adsUniqueClickChart.labels = [];
            this.charts.adsUniqueClickChart.clicks = [];
          } else {
            this.insights = data.insights;
            console.log("updateInsightsData", this.insights);
            let graphics = data.graphics;
            if (graphics !== undefined){
              if (graphics.clicks !== undefined) {
                this.charts.adsClickChart = {...this.charts.adsClickChart, labels: graphics.clicks.labels, data: graphics.clicks.data}
              }
  
              if (graphics.spends !== undefined) {
                this.charts.adsSpendChart = {...this.charts.adsSpendChart, labels: graphics.spends.labels, data: graphics.spends.data}
              }
  
              if (graphics.impressions !== undefined) {
                this.charts.adsImpressionChart = {...this.charts.adsImpressionChart,
                  labels: graphics.impressions.labels,
                  data: graphics.impressions.data
                }
              }
  
              if (graphics.interactions !== undefined) {
                this.charts.adsUniqueClickChart = {...this.charts.adsUniqueClickChart,
                  labels: graphics.interactions.labels,
                  data: graphics.interactions.data
                }
              }
            }
          }
        },
      },
      mounted() {
        if (this.service_path !== ''){
          this.getInsights(
            this.service_path,
            this.account_id
          );
        }
      },
    }
  </script>
  
  <style scoped>
  
  </style>
  