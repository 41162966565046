<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">
          <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
            <div class="card-header border-0" :class="type === 'dark' ? 'bg-transparent' : ''">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    <span v-if="this.selected_integration.service == null">Select a data reception service:</span>
                    <span v-if="this.selected_integration.service != null">Add auth data:</span>
                  </h3>
                </div>
                <div class="col text-right">
                  <base-button type="primary" size="sm" @click="resetService()"
                    v-if="this.selected_integration.service != null">
                    Change service
                  </base-button>
                </div>
              </div>
            </div>

            <div class="flex flex-wrap pl-4 pr-4 pb-4" v-if="this.selected_integration.service == null">
              <table v-if="Object.keys(services).length > 0">
                <tr style="height:50px">
                  <th style="width:200px">Integration</th>
                  <th style="width:calc(100% - 400px)">Info</th>
                  <th style="width:200px"></th>
                </tr>
                <tr style="height:80px; padding: 15px 0" v-for="service in services" :key="service.id">
                  <td>{{ service.name }}</td>
                  <td style="">{{ service.info }}</td>
                  <td style="padding: 0px 0px 0px 80px">
                    <base-button type="primary" size="sm" :block="true"
                      @click="selectIntegration(service)">Select</base-button>
                  </td>
                </tr>
              </table>
              <p v-if="Object.keys(services).length == 0">No services available</p>
            </div>

            <div class="pl-4 pr-4 pb-4" v-if="this.selected_integration.service != null">
              <!-- <div class="step2_integration_container mt-4">
                        <div class="step2_integration_wrap">
                            <Multiselect
                                v-model="selected_integration.type_auth"
                                :options="type_auth"
                                placeholder="Select type"
                                :canDeselect="false"
                            />
                        </div>
                    </div> -->
              <div class="step2_integration_container mt-4">
                <div class="step2_integration_wrap">
                  <Multiselect v-model="selected_integration.auth_data_id" :options="auth_data_for_select"
                    placeholder="Select auth" label="name" valueProp="id" :canDeselect="false" />
                  <base-button type="primary" class="my-4" @click="nextStep()"
                    :disabled="selected_integration.auth_data_id == null"
                    v-if="selected_integration.auth_data_id != 'new'">
                    Next step
                  </base-button>
                </div>
              </div>
              <div class="step2_integration_container mt-4" v-if="selected_integration.auth_data_id == 'new'">
                <div class="step2_integration_wrap">
                  <form class="auth-form"
                    v-if="selected_integration.service && selected_integration.service.auth_template && selected_integration.service.auth_template.field_config"
                    @submit.prevent="nextStep()">

                    <div class="form-group"
                      v-for="(config, key) in selected_integration.service.auth_template.field_config" :key="key">
                      <label :for="key" :class="{ 'checkbox-label': config.type === 'boolean' }">{{ config.title
                        }}</label>
                      <input v-if="config.type !== 'boolean'" :id="key" :type="getInputType(config.type)"
                        v-model="selected_integration.auth_data[ key ]" :readonly="key === 'client_id'" required />
                      <div v-else class="checkbox-group">
                        <input type="checkbox" :id="key" v-model="selected_integration.auth_data[ key ]" />
                      </div>
                    </div>

                    <div class="form-group">
                      <button type="submit" class="btn btn-primary">Submit</button>
                    </div>

                    <div v-if="errorMessage" class="error-message">
                      {{ errorMessage }}
                    </div>
                  </form>
                </div>
              </div>


            </div>

            <!-- <div class="card-footer d-flex justify-content-end" :class="type === 'dark' ? 'bg-transparent' : ''" v-if="this.selected_integration.service != null">
                    <base-button
                        type="primary"
                        class="my-4"
                        @click="nextStep()"
                        :disabled="selected_integration.auth_data_id == null || selected_integration.auth_data.length == 0"
                    >
                        Next step
                    </base-button>
                </div> -->
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<style src="@vueform/multiselect/themes/default.css"></style>
<script>
import { mapGetters } from "vuex";
import apiService from "@/services/api";
import Multiselect from '@vueform/multiselect';
//   import TableIntegrations from "./Tables/TableIntegrations";
export default {
  name: "step2",
  components: {
    Multiselect,
    //   TableIntegrations,
  },
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      auth_data: [],
      integrations: [],
      services: [],
      business_accounts: {},
      current_service: null,
      selected_integration: {
        service: null,
        auth_data: {},
        auth_data_id: null
      },
      errorMessage: null
    };
  },

  computed: {
    ...mapGetters('user', [
      'getUser',
      'getVuexBusinessAccounts',
      'getCurrentBusinessAccount',
    ]),
    auth_data_for_select: function () {
      let data = [
        { 'id': 'new', 'name': 'Create new' }
      ];
      for (const [ key, value ] of Object.entries(this.auth_data)) {
        console.log(`value` + key, value);
        if (value.service_id == this.selected_integration.service.id) {
          let params = { 'id': value.id, 'name': value.name }
          data.push(params);
        }
      }
      //   console.log(`this.selected_integration.service.id`, this.selected_integration.service.id);    
      console.log(`data`, data);
      return data;
    },
  },
  created() {
    this.getBusinessAccounts(this.getUser.id);
    this.getServices();
    this.getAuthData(this.getUser.id);
  },
  methods: {
    getAuthData(user_id) {
      apiService
        .getAuthData(user_id)
        .then((response) => {
          this.auth_data = response.data;
        });
    },
    resetService() {
      this.selected_integration = {
        service: null,
        auth_data: {},
        auth_data_id: null
      }
    },
    nextStep() {
      this.selected_integration.auth_data.integrations_exports_id = this.$route.params.integrations_exports_id;
      this.selected_integration.auth_data.created_by = this.getUser.id;
      this.selected_integration.auth_data.ba_id = this.getCurrentBusinessAccount.id;
      this.selected_integration.auth_data.sending_service_id = this.selected_integration.service.id
      this.selected_integration.auth_data.sending_service_status = this.selected_integration.service.is_active;
      this.selected_integration.auth_data.is_active = false;
      this.selected_integration.auth_data.status = 'disabled';
      this.selected_integration.auth_data.service_type = 'integration';

      if (!this.selected_integration.auth_data.domain) {
        this.selected_integration.auth_data.domain = this.selected_integration.service.path;
      }

      apiService.addAuthData(this.selected_integration.auth_data)
        .then((response) => {
          if (!response.data.success) {
            // Handle the case where success is false. Keep the modal open, only show the error message
            this.errorMessage = response.data.message;
          } else {
            this.$router.push(`/integrations/${this.$route.params.integrations_exports_id}/settings`);
          }
        })
        .catch(error => {
          console.error("Error:", error);
        });
    },
    selectIntegration(service) {
      this.selected_integration.service = service;
      console.log('selected_integration', this.selected_integration);
    },
    checkForBusinessAccount() {
      if (this.business_accounts.length > 0) {
        this.modals.addIntegration = true;
      } else {
        this.$router.push({ name: "business_accounts" });
      }
    },
    getBusinessAccounts(user_id) {
      return apiService
        .getBusinessAccountsByUserId(user_id)
        .then((response) => {
          console.log("getBusinessAccountsByUserId", response);
          this.business_accounts = response.data.data;
          return response;
        });
    },

    getServices() {
      let type = 'sending';
      apiService
        .getServices(this.getUser.id, type)
        .then((response) => {
          console.log("response " + type, response)
          response.data.data.services.forEach((service) => {
            // alert(`${item} має позицію ${index} в масиві ${array}`);
            if (service.auth_template && typeof service.auth_template === 'string') {
              service.auth_template = JSON.parse(service.auth_template);
            }
            this.services.push(service);
          });
          this.integrations = response.data.data.integrations;
          console.log("services: ", this.services)
        });
    },
    returnIntegrationStatus(integration_status) {
      return (integration_status == 1) ? true : false
    },
    changeIntegrationStatus($event, account) {
      console.log('changeIntegrationStatus', account);
      apiService
        .updateStatusIntegration({
          'user_id': this.getUser.id,
          'service_path': account.service.path,
          'integration_id': account.id,
          'status': $event,
        })
        .then((response) => {
          console.log(response);
          if (response.data.service_response.success == true) {
            account.is_active = Boolean($event);
          }
        });
    },

    getServiceName(serviceId) {
      const service = this.services.find(service => service.id === serviceId);
      return service ? service.name : 'Service not found';
    },
    getServicePath(serviceId) {
      const service = this.services.find(service => service.id === serviceId);
      return service ? service.service_path : 'Path not found';
    },
    getInputType(typeString) {
      // You can expand this method to handle different types as needed
      return typeString.includes('string') ? 'text' : 'text'; // default to text for now
    },
  },
  watch: {
    'selected_integration.service.auth_template': {
      deep: true,
      handler() {
        if (this.selected_integration.service && this.selected_integration.service && this.selected_integration.service.field_config) {
          this.selected_integration.auth_data = Object.keys(this.selected_integration.service.field_config).reduce((acc, key) => {
            // Predefine the client_id field with this.getUser.id if it exists
            if (key === 'client_id' && this.getUser && this.getUser.id) {
              acc[ key ] = this.getUser.id;
            } else {
              acc[ key ] = '';
            }
            return acc;
          }, {});
        }
      }
    },
  }
};

if (window.location.hash === "#_=_") {
  history.replaceState
    ? history.replaceState(null, null, window.location.href.split("#")[ 0 ])
    : window.location.hash = "";
}
</script>
<style></style>
