<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8">

    </base-header>

    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col">

          <business-account-menu></business-account-menu>

          <div class="card shadow" :class="type === 'dark' ? 'bg-default' : ''">
            <div
                class="card-header border-0"
                :class="type === 'dark' ? 'bg-transparent' : ''"
            >
              <div class="row align-items-center">
                <div class="col">
                  <h2 class="mb-1 text-blue">
                    Business Accounts: {{business_account.name}}
                  </h2>
                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white' : ''">
                    {{ title }}
                  </h3>
                </div>
                <div class="col text-right">
<!--                  <base-button-->
<!--                      type="primary"-->
<!--                      size="sm"-->
<!--                      @click="modals.addIntegration = true"-->
<!--                  >-->
<!--                    Add integration user-->
<!--                  </base-button>-->
                </div>
              </div>
            </div>

            <div class="table-responsive">
              <base-table
                  class="table align-items-center table-flush table-users"
                  :class="type === 'dark' ? 'table-dark' : ''"
                  :thead-classes="type === 'dark' ? 'thead-dark' : 'thead-light'"
                  tbody-classes="list"
                  :data="users"
              >
                <template v-slot:columns>
                  <th>Id</th>
                  <th>Role</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th></th>
                </template>

                <template v-slot:default="row">
                  <th scope="row">
                    <div class="media align-items-center">
                      <div class="media-body">
                <span class="name mb-0 text-sm">{{
                  row.item.id
                }}</span>
                      </div>
                    </div>
                  </th>
                  <th scope="row">
                    <div class="media align-items-center">
                      <div class="media-body">
                <span class="name mb-0 text-sm">{{
                  row.item.role
                }}</span>
                      </div>
                    </div>
                  </th>
                  <td class="account">
                    {{ row.item.name }}
                  </td>
                  <td class="account">
                    {{ row.item.email }}
                  </td>
                  <td class="settings text-right table-integrations_button">

                    <base-button class="button-settings"
                                 type="warning"
                                 size="sm"
                                 @click="open_settings_modal(row.item)"
                    >
                      Settings
                    </base-button>
<!--                    <base-button-->
<!--                        class="button-delete"-->
<!--                        type="default"-->
<!--                        size="sm"-->
<!--                    >-->
<!--                      Disable-->
<!--                    </base-button>-->
                  </td>
                </template>
              </base-table>
            </div>

            <div
                class="card-footer d-flex justify-content-end"
                :class="type === 'dark' ? 'bg-transparent' : ''"
            >
            <base-pagination
                :pageCount="pagination.last_page"
                :value="pagination.current_page"
                @input="changePage"
                v-if="users.length > 0"
            ></base-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal v-model:show="modals.open_settings">
      <template v-slot:header>
        <h3 class="modal-title" id="modal-title-default">User Settings</h3>
      </template>

      <div class="flex flex-wrap">
        <div class="wrap_user_settings">
          <div class="user_settings title_user_settings">
            <p>Id</p>
          </div>
          <div class="user_settings text_user_settings">
            <p>{{user_settings.id}}</p>
          </div>
        </div>

        <div class="wrap_user_settings">
          <div class="user_settings title_user_settings">
            <p>Role</p>
          </div>
          <div class="user_settings text_user_settings">
            <p>{{user_settings.role}}</p>
          </div>
        </div>

        <div class="wrap_user_settings">
          <div class="user_settings title_user_settings">
            <p>Name</p>
          </div>
          <div class="user_settings text_user_settings">
            <p>{{user_settings.name}}</p>
          </div>
        </div>

        <div class="wrap_user_settings">
          <div class="user_settings title_user_settings">
            <p>Email</p>
          </div>
          <div class="user_settings text_user_settings">
            <p>{{user_settings.email}}</p>
          </div>
        </div>

        <div class="wrap_user_settings">
          <div class="user_settings title_user_settings">
            <p>Last name</p>
          </div>
          <div class="user_settings text_user_settings">
            <p>{{user_settings.last_name}}</p>
          </div>
        </div>

        <div class="wrap_user_settings">
          <div class="user_settings title_user_settings">
            <p>Phone</p>
          </div>
          <div class="user_settings text_user_settings">
            <p>{{user_settings.phone}}</p>
          </div>
        </div>

        <div class="wrap_user_settings">
          <div class="user_settings title_user_settings">
            <p>Telegram</p>
          </div>
          <div class="user_settings text_user_settings">
            <p>{{user_settings.telegram}}</p>
          </div>
        </div>

        <div class="wrap_user_settings">
          <div class="user_settings title_user_settings">
            <p>Facebook</p>
          </div>
          <div class="user_settings text_user_settings">
            <p>{{user_settings.facebook}}</p>
          </div>
        </div>

      </div>

      <template v-slot:footer>
<!--        <base-button-->
<!--            type="link"-->
<!--            class="ml-auto"-->
<!--            @click="modals.open_settings = false"-->
<!--        >Close-->
<!--        </base-button>-->
      </template>
    </modal>
  </div>

</template>
<script>

import {mapGetters} from "vuex";
import adminService from "@/services/admin";
import BusinessAccountMenu from "@/components/Admin/BusinessAccountMenu.vue"

export default {
  name: "users",
  components: {
    BusinessAccountMenu,
  },
  props: {
    type: {
      type: String,
    },
  },
  data() {
    return {
      title: 'Users',
      users: [],
      user_settings: {},
      modals: {
        open_settings: false,
      },
      pagination: {
        last_page: 1,
        current_page: 1,
      },
      business_account: {
        id: null,
        name: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  created() {
    console.log("user", this.user);
    console.log('route',this.$route.query);
    this.business_account.id = this.$route.params.id_ba;
    this.getBusinessAccountInfo();

    if (this.$route.query.page !== undefined){
      this.pagination.current_page = Number(this.$route.query.page);
    }
    this.getUsers(this.pagination.current_page);
  },
  methods: {
    getUsers(page) {
      let params = {
        'page': page,
        'business_account_id': this.business_account.id
      };
      adminService
        .getUsers(params)
        .then((response) => {
          console.log(response);
          this.users = response.data.data.users.data;
          this.pagination.last_page = Number(response.data.data.users.last_page);
        });
    },
    getBusinessAccountInfo() {
      let params = {
        'id': this.business_account.id
      };
      adminService
        .getBusinessAccountInfo(params)
        .then((response) => {
          console.log(response);
          this.business_account = response.data.data.business_account;
        });
    },
    open_settings_modal(user){
      console.log('open_settings_modal', user);
      this.user_settings = JSON.parse(JSON.stringify(user));
      this.modals.open_settings = true;
    },
    changePage(page){
      this.pagination.current_page = page;
      this.getUsers(page);
      this.$router.push({ name: 'admin_ba_users', query: {page: page} });
    }
  },
};
</script>
<style></style>
